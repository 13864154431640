import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { GlobalvariablesService } from '../globalvariables.service';
import { ApiService } from '../api.service';
import { Product } from 'src/Classes/Product';
import { Basket_Line } from 'src/classes/basket_line';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss']
})
export class BasketComponent implements OnInit {

  constructor( private router:Router, private gVars:GlobalvariablesService, private apiService : ApiService) { }

  BasketProducts : any =0;
  noProds=true;
  contains=true;
  showEmpty=false;

  QOH = 0;
  DesiredQ = 0;
  Cost=0;

  qUpdated=false;
  updateError=false;

  updateLine : Basket_Line = new Basket_Line();

  currentProduct=0;

  async ngOnInit() {
 
    // check whether client is logged in. if so, nothing, otherwise redirect to login screen
    if (this.gVars.getClientID() == 0){
      this.router.navigate(['/login']);
    }
    this.apiService.getBasketItemDetails(this.gVars.getBasketID()).toPromise().then(data => {
      //this.BasketProducts = data;
      this.setData(data);
      if(data=="")
      {
        this.noProds=false;
      }
   })
  }

  setData(dataX:any)
  {
    this.BasketProducts = dataX;
    if(this.BasketProducts.length==0)
    {
      this.contains=false;
      this.showEmpty=true;
    }
  }
  
  async deleteConfirm()
  {
     await this.apiService.removeBasketItem(this.gVars.remID, this.gVars.getBasketID()).toPromise().then(y => {
      console.log(y);
    });
    this.ngOnInit()
  }

  removeItem(x:number){
   
    this.gVars.remID=x;
    // this.router.navigate(['/confirmitemremove']);


  }

  adjustQ(x:number){
    for(let y:number = 0 ; y<this.BasketProducts.length ;y++)
    {
      if(this.BasketProducts[y].ProductID==x){
        this.QOH = this.BasketProducts[y].QuantityOnHand;
        this.DesiredQ = this.BasketProducts[y].Quantity;
        this.Cost = this.BasketProducts[y].Cost;
      }
    }
    this.currentProduct=x;
  }

  saveQuantity(nQuantity){
    this.updateLine.BasketID = this.gVars.getBasketID();
    this.updateLine.ProductID = this.currentProduct;
    this.updateLine.Quantity = nQuantity.quantity;
    this.updateLine.Cost = this.Cost;
    debugger;

    //console.log(this.updateLine);
    this.apiService.UpdateBasketQuantity(this.updateLine).subscribe(r=> {
      console.log(JSON.stringify(r));
      this.getReturnData(r);
    })  
  }

  getReturnData(r:any){
    this.updateError = false;
    if(r=="operation succeded!")
    {
      this.qUpdated = true;
      this.apiService.getBasketItemDetails(this.gVars.getBasketID()).toPromise().then(data => {
        this.BasketProducts = data;
        if(data=="")
        {
          this.noProds=false;
        }
     })
    }else{
      this.updateError = true;
    }

  }

  showModalBox: boolean = false;
 
  public open() {
    if(0){
      // Dont open the modal
      this.showModalBox = false;
    } else {
       // Open the modal
       this.showModalBox = true;
    }

  }


  
}
