<!-- banner -->
<div class="banner-w3ls-2">

</div>
<!-- //banner -->
<!-- page details -->
<div class="breadcrumb-agile">
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a href="index.html">Home</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Products</li>
    </ol>
</div>
<!-- //page details -->

<!-- gallery -->
<div class="gallery-agile py-5">
    <div class="container py-xl-5 py-lg-3">
        <!-- heading title -->
        <div class="main-title text-center mb-5 pb-3">
            <h3 class="tittle text-dark font-weight-bold">Products</h3>
           
        </div>
        <!-- //heading title -->




 <!-- Product Shop Section Begin -->























    </div>
</div>
<!-- //gallery -->
<section class="product-shop spad">
    <div class="container">
        <div class="row">


                <div class="product-list">
                    <div class="row">

                        <div class="col-lg-4 col-md-6 col-sm-12" *ngFor ="let product of allProducts;">
                            
                            <form [formGroup]="productForm" (ngSubmit)="onSubmit()">  
                            <div  class="product-item " >

                                <div [routerLink]="['/product',product.ProductName ]">
                                <div class="pi-pic">
                                    <img [src]='product.ProductImage' alt="" class="img-fluid zoom-img" >
                                    <div class="">
                                        <div class="text"  >{{product.ProductName}}</div>
                                      </div>
                                      <!-- (click)="open(product.ProductID)" -->
                                </div>
                            </div>
                                <div class="pi-text ">
                                    <div [routerLink]="['/product',product.ProductName ]">
                                    <div class="catagory-name">{{product.ProductName}}</div>

                                        <h5>{{product.ProductQOH}} left!</h5> 

                                    <div  class="product-price">
                                       <input value="{{product.ProductRetailPrice}}" formControlName="Price"  hidden>
                                       
                                        R{{product.ProductRetailPrice}}

                                    </div>
                                    <div   >
                                        <input [value]="product.ProductID"  type="text" formControlName="ProductID" hidden >
                                    </div>
                                </div>

                                    <div >

                                        <input  class="w-100" style="width: 100px;" #quantity [hidden]="product.ProductQOH <1" type="number"   min="1" placeholder="Quantity" [max]="product.ProductQOH" formControlName="Quantity">
                                            <p [hidden]="product.ProductQOH">Out of Stock</p>
                                    <!-- <mat-form-field >
                                        
                                      </mat-form-field> -->
                                      <br>
                                    </div>
                                    
                                    <div ><button [disabled]="quantity.value==''" [hidden]="product.ProductQOH <1" class="btn btn-success w-100 border"  type="submit"  (click)="addItemsToBasket({id: product.ProductID, price: product.ProductRetailPrice,quantity: quantity.value}); quantity.value=''; ">Add to basket</button></div>
                                    <!-- (click)="addItemsToBasket();" -->
                                    <!-- (click)="showModal(1) || basicModal2.show()" -->
                                </div>
                            </div>
                        </form>  
                        </div>





                    </div>
                    </div>


                    </div>

                    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div *ngFor="let product of allProducts ">
                                <ng-container *ngIf="product.ProductID==productId">
                              
                             
                          <div class="modal-content">
                            <div class="modal-header alert-success">
                              <h3 class="modal-title" id="exampleModalLabel">{{product.ProductName}}</h3>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                                <div >Description: <br>{{product.ProductDescription}}</div> <br> <br>
                                <div >Usage: <br>{{product.ProductUsages}}</div>

                            </div>
                     
                            <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                          </div>
                        </ng-container> 
                    </div>
                        </div>
                      </div>




              
    </div>
 </section>

