<!-- banner -->
<div class="banner-w3ls-2">

</div>
<!-- //banner -->
<!-- page details -->
<div class="breadcrumb-agile">
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a href="index">Home</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
    </ol>
</div>
<!-- //page details -->

<!-- contact -->
<div class="address py-5" id="contact">
    <div class="container py-xl-5 py-lg-3">
        <!-- heading title -->
        <div class="main-title text-center mb-5 pb-3">
            <h3 class="tittle text-dark font-weight-bold">Contact Us</h3>
           
        </div>
        <!-- //heading title -->
        <div class="row address-row pt-lg-5">
            <div class="col-lg-6">
                <div class="address-right p-sm-5 p-4">
                    <div class="address-info wow fadeInRight animated" data-wow-delay=".5s">
                        <h4 class="mb-3">Address</h4>
                        <p>Emalahleni
                            <span>Mpumalanga, South Africa</span>
                        </p>
                    </div>
                    <div class="address-info address-mdl wow fadeInRight animated my-sm-5 my-4" data-wow-delay=".7s">
                        <h4 class="mb-3">Phone </h4>
                        <p>+27 81 564 7101</p>
                    </div>
                    <div class="address-info agileits-info wow fadeInRight animated" data-wow-delay=".6s">
                        <h4 class="mb-3">Mail</h4>
                        <p>
                            <a href="mailto:info@pfusheo.co.za">info@pfusheo.co.za</a>
                        </p>
                        
                    </div>
                </div>
            </div>
            <div class="col-lg-6 address-left wow agile fadeInLeft animated mt-lg-0 mt-5" data-wow-delay=".5s">
                <div class="address-grid p-sm-5 p-4">
                    <h4 class="wow fadeIndown animated mb-3" data-wow-delay=".5s">Get In Touch</h4>
                    <form action="#" method="post">
                        <div class="form-group">
                            <input type="text" placeholder="Name" name="name" class="form-control" required="">
                        </div>
                        <div class="form-group">
                            <input type="email" placeholder="Email" name="email" class="form-control" required="">
                        </div>
                        <div class="form-group">
                            <textarea placeholder="Message" name="Message" class="form-control" required=""></textarea>
                        </div>
                        <input type="submit" value="SEND">
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- //contact -->
<!-- map -->
<div class="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d235182.3477065605!2d30.350116440238327!3d-22.923542032972005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ec5c72c7472097b%3A0xd5d5f24ad8f27122!2sThohoyandou!5e0!3m2!1sen!2sza!4v1594414568117!5m2!1sen!2sza" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
</div>
<!-- //map -->