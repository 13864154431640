<!-- banner -->
<div class="banner-w3ls-2">

</div>
<!-- //banner -->
<!-- page details -->
<div class="breadcrumb-agile">
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a href="index.html">Home</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Basket</li>
    </ol>
</div>
<!-- //page details -->





<div *ngIf="qUpdated" class="alert alert-success">
  Quantity Updated!
</div>

<div *ngIf="updateError" class="alert alert-danger">
  Quantity Update Failed!
</div>

 <!--This is the help icon, when clicked, modal pops up-->
 <!-- <mdb-icon fas icon="question-circle" (click)="basicModal2.show()"></mdb-icon> -->
 <div *ngIf="showEmpty" style="padding-top:15px; text-align: center;">
    <button type="button"  block="true" class="btn btn-success"  routerLink="/product">Your Basket is Empty! Start Shopping Now?</button>
</div>
<div *ngIf="contains">
<table  class="table" style="margin-bottom: 70px;">
    <thead class="black white-text">
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Quantity</th>
        <th scope="col">Cost</th>
        <th scope="col">Image</th>
        <th scope="col">Remove</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let product of BasketProducts">
        <th>{{product.ProductName}}</th>
        <td style="font-weight: bold;text-align: center; padding:20px;">x{{product.Quantity}} <a class=" material-icons icons"  style=" color: chartreuse; font-size: 20px; vertical-align: text-bottom; height: auto; cursor: pointer;	" > <span  data-toggle="modal" data-target="#adjustQModal"  (click)="adjustQ(product.ProductID) " >edit</span></a>

        </td>
        <td style="font-weight: bold;text-align: center; padding:20px;">R {{product.Cost}}.00</td>          
        <!-- <i class="fas fa-wrench" style="color: chartreuse;"></i> -->
        <td><img id="image" class="img-fluid zoom-img"  width="125px"  [src]="product.ProductImage"/></td>
        <td style=" padding:40px;">
        <a   style=" color: red; font-size: 20px; vertical-align: text-bottom; height: auto;	" ><i class=" material-icons icons" ><span data-toggle="modal" data-target="#deleteItem" (click)="removeItem(product.ProductID)">delete</span></i></a></td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td><button *ngIf="noProds" type="button" routerLink="/product" class="btn aqua-gradient" data-toggle="modal" data-target="#basicExampleModal">
          Shop
        </button></td>
        <td>    
        <button *ngIf="noProds" type="button" routerLink="/checkout" class="btn aqua-gradient" data-toggle="modal" data-target="#basicExampleModal">
            Checkout
          </button>     
        </td>
      </tr>
      
    </tbody>
  </table>
  </div>

      <!-- Modal -->
      <div  #adjustQModal class="modal fade" id="adjustQModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header alert-primary ">
              <h3 class="modal-title text-center" id="exampleModalLabel" style="text-align: center;">Adjust your desired Quanity</h3>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <p>We have {{QOH}} of this item remaining!</p>
                <label for="exampleFormControlInput1">Quantity</label>
                <input #quantity type="number" class="form-control" id="exampleFormControlInput1" min="1" max="{{QOH}}" placeholder="">
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
              <button type="button" class="btn btn-success" (click)="saveQuantity({quantity: quantity.value}) ; quantity.value=''; ">Save</button>
            </div>
          </div>
        </div>
      </div>

  

      <div #deleteItem class="modal fade" id="deleteItem" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header alert-danger ">
              <h3 class="modal-title text-center" id="exampleModalLabel" style="text-align: center;">Remove Basket Item </h3>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
             <h2>Are you sure you want to remove the item?</h2>
            </div>
            <div class="modal-footer " >
              <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
              <button type="submit" class="btn btn-success" data-dismiss="modal" (click)="deleteConfirm()">Confirm</button>
            </div>
          </div>
        </div>
      </div>
