import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { GlobalvariablesService } from '../globalvariables.service';
import { Router, RouterModule, Routes } from '@angular/router';
import { DomSanitizer, SafeHtml} from '@angular/platform-browser';
import { Client_Query } from 'src/classes/Client_Query';


@Component({
  selector: 'app-clientquery',
  templateUrl: './clientquery.component.html',
  styleUrls: ['./clientquery.component.scss'],

})
export class ClientqueryComponent implements OnInit {

  queryTopic = "Payments";
  queryMessage = "";
  invTopic=false;
  invMessage=false;
  newQ= new Client_Query();
  x:any;
  updateSuccess=false;
  noMessage=false;

  constructor(private apiService: ApiService, private gVars:GlobalvariablesService, private router:Router,private sr: DomSanitizer) { }

  ngOnInit() {
    
  }

  submitQuery() {
    this.noMessage=false;
    if(this.queryMessage=="")
    {
      this.noMessage=true;
    }else{
      this.invTopic=false;
      this.invMessage=false;
      if(this.queryTopic=="")
      {
        this.invTopic=true;
      }
      if(this.queryMessage=="")
      {
        this.invMessage=true;
      }
      if(!this.invMessage && !this.invTopic)
      {
        //alert(this.sanitize(this.queryMessage));
        this.newQ.ClientID=this.gVars.getClientID();
        this.newQ.QueryStatusID=1;
        this.newQ.QueryMessage=this.queryMessage;
        
        /*switch(this.queryTopic){
          case this.queryTopic='Payments': {this.newQ.QueryTypeID=1};
          case this.queryTopic='General Query': {this.newQ.QueryTypeID=2};
          case this.queryTopic='Products': {this.newQ.QueryTypeID=3};
          case this.queryTopic='Orders': {this.newQ.QueryTypeID=4};
          case this.queryTopic='Account': {this.newQ.QueryTypeID=5};
        }*/
  
        if(this.queryTopic=="Payments"){
          this.newQ.QueryTypeID=1;
        }
        if(this.queryTopic=="General Query"){
          this.newQ.QueryTypeID=2;
        }
        if(this.queryTopic=="Products"){
          this.newQ.QueryTypeID=3;
        }
        if(this.queryTopic=="Orders"){
          this.newQ.QueryTypeID=4;
        }
        if(this.queryTopic=="Account"){
          this.newQ.QueryTypeID=5;
        }
  
        this.newQ.QueryDate = new Date();
  
        this.apiService.addQuery(this.newQ).subscribe(h => {
          console.log(h);
          JSON.stringify(h);
        }, err =>  {
          if(err.error=="failed")
          {
            alert("failed");
          }
        });
      }
      this.updateSuccess=true;
    }
  }

  sanitize(x:string) : SafeHtml {
    return this.sr.bypassSecurityTrustHtml(x);
 }

}
