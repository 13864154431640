
<!--This is the help icon, when clicked, modal pops up-->
<!-- <mdb-icon fas icon="question-circle" (click)="basicModal.show()"></mdb-icon> -->
<section class="mb-4">   
        <div class="row"  style="margin-bottom:100px;">    
            <div class="col-md-9 mb-md-0 mb-5">
                <form id="clientQueryForm" name="clientQueryForm">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="md-form mb-0">
                                    <h3>Query Type:</h3>
                                    <div *ngIf="invTopic" class="alet alert-danger">Please Select a Topic</div> 
                                    <select id="queryType" name="queryType" [(ngModel)]="queryTopic" style="margin-top:20px; margin-bottom: 40px;">
                                            <option value="Payments">Payments</option>
                                            <option value="General Query">General Query</option>
                                            <option value="Products">Products</option>
                                            <option value="Orders">Orders</option>
                                            <option value="Account">Account</option>
                                    </select>
                                    
                            </div>
                            
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-md-12">   
                            <div class="md-form">
                                    <div *ngIf="noMessage" class="alet alert-danger">Please enter a message!</div> 
                                <h3>Your Message:</h3>
                                <div *ngIf="invMessage" class="alet alert-danger">Please Enter Your query</div>
                                <textarea type="text" id="message" [(ngModel)]="queryMessage" name="message" rows="6" class="form-control md-textarea"></textarea>   
                                                              
                            </div>
                              
                        </div>
                    </div>
                </form>
                
                <div class="text-center text-md-left">
                       
                    <button (click)="submitQuery()" type="button" class="btn btn-dark-green" style="background-color: darkgreen">Submit</button>
                    <button routerLink="/querymenu" type="button" class="btn btn-dark-green" style="background-color: darkgreen">Cancel</button>
                </div>
                <div *ngIf="updateSuccess" class="alert alert-success">
                    Query Saved!
                    <button mdbBtn color="dark-green" class="my-4" type="submit" routerLink="/account">My Account</button>
            </div>
                <div class="status"></div>
            </div>

            <div class="col-md-3 text-center">
                <ul class="list-unstyled mb-0">
                    <li><i class="material material-icons">location_on</i>
                        <p>Thohoyandou, Limpopoo</p>
                    </li>
    
                    <li><i class="material material-icons">phone</i>
                        <p>+27 81 564 7101</p>
                    </li>
    
                    <li><i class="material material-icons">email</i>
                        <p>info@pfusheo.co.za</p>
                    </li>
                </ul>
            </div>
    
        </div>  
    </section>
    <!--End of client query section-->

<!--HTML for HELP MODAL. Load help message from database-->
<div  class="modal fade customDiv" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
        <div class="modal-dialog customModal" role="document">
          <div class="modal-content customModal">
            <div class="modal-header customModal">
              <button type="button" class="close pull-right" aria-label="Close" >
                <span aria-hidden="true">×</span>
              </button>
              <h4 class="modal-title w-100" id="myModalLabel" style="color:green">How do client queries work?</h4>
            </div>
            <div class="modal-body customModal">
              <p>This is the new query screen, on which you can submit a new query to us.</p>
              <p>Simply type your query into the message box and submit</p>
              <p>The query will be saved to our database and answered by an employee as soon as possible</p>
              <p>You can review queries from the Account Menu</p>
            </div>
            <div class="modal-footer customModal">
              <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close"  mdbWavesEffect>Close</button>
            </div>
          </div>
        </div>
      </div>
      <!--End of help modal-->

      