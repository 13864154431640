import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { ProductComponent } from './product/product.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { BasketComponent } from './basket/basket.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { AccountComponent } from './account/account.component';
import { VerifyComponent } from './verify/verify.component';
import { PaycancelComponent } from './paycancel/paycancel.component';
import { PaysuccessComponent } from './paysuccess/paysuccess.component';
import { ViewordersComponent } from './vieworders/vieworders.component';
import { EditaccountdetailsComponent } from './editaccountdetails/editaccountdetails.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { QuerymenuComponent } from './querymenu/querymenu.component';
import { ViewqueriesComponent } from './viewqueries/viewqueries.component';
import { ClientqueryComponent } from './clientquery/clientquery.component';
import { Product } from 'src/Classes/Product';
import { ProductdetailComponent } from './productdetail/productdetail.component';


const routes: Routes = [
  { path: 'index', component: IndexComponent },
  { path: '', component: IndexComponent },
  { path: 'about', component: AboutComponent },
  { path: 'product', component: ProductComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'basket', component: BasketComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'account', component: AccountComponent },
  { path: 'verify', component: VerifyComponent },
  { path: 'paycancel', component: PaycancelComponent },
  { path: 'paysuccess', component: PaysuccessComponent },
  { path: 'vieworders', component: ViewordersComponent },
  { path: 'editaccountdetails', component: EditaccountdetailsComponent },
  { path: 'resetpassword', component: ResetpasswordComponent },
  { path: 'querymenu', component: QuerymenuComponent },
  { path: 'viewqueries', component: ViewqueriesComponent},
  { path: 'clientquery', component: ClientqueryComponent},

  { path: 'product/:name', component: ProductdetailComponent},

  

  { path: 'contact', component: ContactComponent },
  { path: '**', redirectTo: '' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
