import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { GlobalvariablesService } from '../globalvariables.service';
import { ApiService } from '../api.service';
import { Product } from 'src/Classes/Product';
import { Order } from 'src/classes/order';

@Component({
  selector: 'app-vieworders',
  templateUrl: './vieworders.component.html',
  styleUrls: ['./vieworders.component.css']
})
export class ViewordersComponent implements OnInit {

  constructor( private router:Router, private gVars:GlobalvariablesService, private apiService : ApiService) { }

  orders: any=[];
  items: any=[];
  deletables: any=[];
  contains=true;
  showEmpty=false;
  saveInProgress=false;
  modalImage="";
  showModalBox: boolean = false;
  number=0;

  async ngOnInit() {
    console.log(this.gVars.getClientID());
    await this.apiService.getOrders(this.gVars.getClientID()).toPromise().then(data => {
      //this.orders = data;
      //this.orders = data;
      this.setData(data);
      this.orders=["orders"]
      console.log(JSON.stringify(data));
   })
    
  }

  public open(id:any) {
    if(0){
      // Dont open the modal
      
      this.showModalBox = false;
    } else {
       // Open the modal
       this.number=id;
       this.showModalBox = true;
    }
  }

  setData(dataX:any)
  {
    this.orders = dataX;

    for(let z=0; z < this.orders.length; z++)
    {
      if(this.orders[z].OrderStatus=="Placed")
      {
        
        this.deletables.push(this.orders[z]);
      }
    }

    if(this.orders.length==0)
    {
      this.contains=false;
      this.showEmpty=true;
    }
  }

  showModal(x:number){

    for(let y=0; y < this.orders.length ; y++)
    {
      if(this.orders[y].OrderID ==x)
      {
        this.items = this.orders[y].Orders;
        this.modalImage="assets/"+this.orders[y].OrderStatus+".png";
      }
    }
    
  }

  cancelOrder(ordID:number)
  {
    this.saveInProgress=true;
    document.getElementById("overlay").style.display = "block";

    this.apiService.cancelOrder(ordID).subscribe(j=>{

      this.getResponse(j);
    });
  }

  getResponse(j:any)
  {

    this.saveInProgress=false;
    document.getElementById("overlay").style.display = "none";
    if(j==true)
    {
      location.href = location.href;
    }
  }
}
