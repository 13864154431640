import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { GlobalvariablesService } from '../globalvariables.service';
import { ApiService } from '../api.service';
import { Hash } from 'src/classes/hash';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  
  constructor(private router:Router, private gVars:GlobalvariablesService, private apiService : ApiService) { }

  BasketProducts : any = [];
  totalPrice = 0;
  totalQuantity = 0;
  order : any =[];
  fileToUpload: File = null;
  MD5string :string = '';
  payable=false;
  obj:Hash= new Hash();

  async ngOnInit() {
    if(sessionStorage.getItem('verified')=="false")
    {
      this.router.navigate(['/account']);
    }
    // check whether client is logged in. if so, nothing, otherwise redirect to login screen
    if (this.gVars.getClientID() == 0){
      this.router.navigate(['/login']);
    }
    await this.apiService.getBasketItemDetails(this.gVars.getBasketID()).toPromise().then(data => {
      this.BasketProducts = data;
   })
   this.getTotal();
   this.genMD5();
  }

  getTotal(){
    for( let x=0; x<this.BasketProducts.length; x++)
    {
      this.totalPrice += this.BasketProducts[x].Cost;
      this.totalQuantity += this.BasketProducts[x].Quantity;
    }
  }

  async genMD5()
  {
    this.MD5string = "merchant_id=10000100$merchant_key=46f0cd694581a$return_url=http%3A%2F%2Ffrontend.lammed.co.za%2Fpaysuccess$cancel_url=http%3A%2F%2Ffrontend.lammed.co.za%2Fpaycancel$amount="+this.totalPrice+"$item_name=PfusheoOrder";
    //alert(this.MD5string);
    this.obj.hashString = this.MD5string;
    await this.apiService.getMD5(this.obj).toPromise().then( x =>{
      this.payable=true;
      this.MD5string = x.toString();
      //alert(this.MD5string);
    });
  }

  createOrder()
  {
    /*this.order.ClientID = this.gVars.getClientID();
    this.order.BasketID = this.gVars.getBasketID();
    this.order.TotalCost = this.totalPrice;
    this.order.OrderDate = new Date().toISOString().slice(0,10);
    if(this.gVars.getClientTypeID()==1)
    {
      this.order.OrderType = "Private";
    }else{
      this.order.OrderType = "Retail";
    }
    console.log(this.order);
    this.apiService.createOrder(this.order).subscribe( p => {
      console.log("success");
    });
    this.router.navigate(['/account']);*/
  }
}
