import { Component, NgModule, OnInit } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { GlobalvariablesService } from '../app/globalvariables.service';
import { HttpClientModule } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'mdb-angular-free';
  constructor(private router:Router, public gVars:GlobalvariablesService) { }

  ee:boolean=false;
  privateAccount:boolean=false;
  retailAccount:boolean=false;
  fName:string="";
  isLoggedIn = false;
  notLoggedIn = true;

  /*setPrivate(x:boolean)
  {
    this.privateAccount = x;
    this.fName = "Welcome "+this.gVars.getClientName();
  }

  setRetail(x:boolean)
  {
    this.retailAccount = x;
    this.fName = "Welcome "+this.gVars.getClientName();
  }*/

  logout() {
    //this.appC.setPrivate(false);
    //this.appC.setRetail(false);
    this.gVars.logout();
    this.gVars.heading="";
    this.router.navigate['/product'];
  }

  
  ngOnInit(){
    
    //this.router.navigate(['/shop']);

    if(sessionStorage.getItem('loggedInStatus')=="true")
    {
      this.gVars.setBaskettID(+sessionStorage.getItem('basketID'));
      this.gVars.setClientAddressID(+sessionStorage.getItem('clientAddressID'));
      this.gVars.setClientCell(sessionStorage.getItem('clientCell'));
      this.gVars.setClientEmail(sessionStorage.getItem('clientEmail'));
      this.gVars.setClientID(+sessionStorage.getItem('clientID'));
      this.gVars.setClientName(sessionStorage.getItem('clientName'));
      this.gVars.setClientSurname(sessionStorage.getItem('clientSurname'));
      this.gVars.setClientTypeID(+sessionStorage.getItem('clientTypeID'));
      this.gVars.setUserID(+sessionStorage.getItem('userID'));
      this.gVars.setStreetName(sessionStorage.getItem('streetName'));
      this.gVars.setStreetNumber(sessionStorage.getItem('streetNumber'));
      this.gVars.setPostalCode(sessionStorage.getItem('postalCode'));
      this.gVars.setProvince(sessionStorage.getItem('province'));
      this.ee= (/true/i).test(sessionStorage.getItem('loggedInStatus'));
      this.gVars.setLoggedInStatus(this.ee);
      if(sessionStorage.getItem('verified')=="true")
      {
        this.gVars.setVerified(true);
      }else{
        this.gVars.setVerified(false);
      }

      if(this.gVars.getClientTypeID()==1)
      {
        //this.appC.setPrivate(true);
        this.gVars.heading = "Welcome " + this.gVars.getClientName() + "!";
      }
      if(this.gVars.getClientTypeID()==2)
      {
        //this.appC.setRetail(true);
        this.gVars.heading = "Welcome " + this.gVars.getClientName() + "! Retail Account";
      }

    }
  }
}

