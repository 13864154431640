 <!--This is the help icon, when clicked, modal pops up-->
 <!-- <mdb-icon fas icon="question-circle" (click)="basicModal.show()"></mdb-icon> -->
 <div>
    <button type="button" mdbBtn block="true" color="dark-green" mdbWavesEffect routerLink="/querymenu">Back</button>
</div>
<div *ngIf="showEmpty" style="padding-top:15px;">
    <button type="button" mdbBtn block="true" color="dark-green" mdbWavesEffect routerLink="/querymenu">No Queries Submitted! Back to Menu?</button>
</div>

<div *ngIf="contains">
<table  class="table" style="margin-bottom: 70px;">
    <thead class="black white-text">
      <tr>
        <th scope="col">Query ID</th>
        <th scope="col">Status Description</th>
        <th scope="col">Query Type</th>
        <th scope="col">Query</th>
        <th scope="col">Answer</th>
        <th scope="col">Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let cur of pop">
        <th>{{cur.QueryID}}</th>
        <td>{{cur.StatusDescription}}</td>
        <td>{{cur.QueryTypeDescription}}</td> 
        <td>{{cur.QueryMessage}}</td>   
        <td>{{cur.QueryAnswer}}</td>   
        <td>{{cur.QueryDate}}</td> 
        <td></td>        
      </tr>
      
    </tbody>
  </table>
</div>


  <!--HTML for HELP MODAL. Load help message from database-->
<div  class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close pull-right" aria-label="Close" >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title w-100" id="myModalLabel" style="color:green">View Queries</h4>
        </div>
        <div class="modal-body">
          <p>This is the View Queries screen. Here is listed all of your queries, and information regarding each one.</p>
        </div>
        <div class="modal-footer">
          <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close"  mdbWavesEffect>Close</button>
        </div>
      </div>
    </div>
  </div>
  <!--End of help modal-->