 <!--This is the help icon, when clicked, modal pops up-->
 <!-- <mdb-icon fas icon="question-circle" (click)="basicModal.show()"></mdb-icon> -->
 <div>
    <button type="button"  block="true" color="dark-green" mdbWavesEffect routerLink="/account">Back</button>
</div>
<div *ngIf="showEmpty" style="padding-top:15px;">
    <button type="button"  block="true" color="dark-green" mdbWavesEffect routerLink="/basket">No Orders yet! Start Shopping Now?</button>
</div>
<div *ngIf="contains">
<!-- <table  class="table" >
    <thead class="black white-text">
      <tr>
        <th scope="col">Order ID</th>
        <th scope="col">Order Status</th>
        <th scope="col">Waybill</th>
        <th scope="col">Order Type</th>
        <th scope="col">Total Cost</th>
        <th scope="col">Order Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let order of orders">
        <th>{{order.OrderID}}</th>
        <td>{{order.OrderStatus}}</td>
        <td>{{order.WaybillNumber}}</td> 
        <td>{{order.OrderType}}</td>   
        <td>R {{order.TotalCost}}.00</td>   
        <td>{{order.OrderDate}}</td>         
        <td style=" padding:40px;"><a (click)="showModal(order.OrderID); "><i class="fas fa-eye" style="color:green"></i></a></td>
      </tr>
      
    </tbody>
  </table> -->
  <div><h3>Past Orders</h3></div>
  <table  class="table" style="margin-bottom: 50px;">
    

      <thead class="red white-text">
        <tr>
          <th scope="col">Order ID</th>
          <th scope="col">Order Type</th>
          <th scope="col">Total Cost</th>
          <th scope="col">Order Date</th>
          <th scope="col" class="text-center" >More Info</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let del of deletables ">
          <ng-container >
          <td>{{del.OrderID}}</td>
          <td>{{del.OrderType}}</td>   
          <td>R {{del.TotalCost}}</td>   
          <td>{{del.OrderDate}}</td> 
          <td class="text-center"><a class=" material-icons icons "  data-toggle="modal" data-target="#exampleModal" style="font-size: 20px; vertical-align: text-bottom; height: auto;	" (click)=open(del.OrderID)>remove_red_eye</a>
          </td>
        </ng-container>     
          <!-- <td style=" padding:40px;"><a (click)="cancelOrder(del.OrderID)"><i class="fas fa-trash-alt" style="color:red;"></i></a></td> -->


         

        </tr>
       
        
      </tbody>
    
    </table>


<div *ngIf="showModalBox"  class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="exampleModalLabel">Order Detail</h1>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
    
            <div *ngFor="let del of deletables ">
              <ng-container *ngIf="del.OrderID==number">
              <div >OrderID: {{del.OrderID}}</div>
              <div>OrderType: {{del.OrderType}}</div>   
              <div>TotalCost: R {{del.TotalCost}}</div>   
              <div>OrderDate: {{del.OrderDate}}</div>    
            </ng-container>     
              <!-- <td style=" padding:40px;"><a (click)="cancelOrder(del.OrderID)"><i class="fas fa-trash-alt" style="color:red;"></i></a></td> -->
    
              <ng-container *ngIf="del.OrderID==number">
            <table class="table">
              <tr>
                <th>Product</th>
                <th>Quantity</th>
                <th>CostOfProduct</th>
    
              </tr>
              <tr *ngFor="let dels of del.Orders">
                
                  <td>{{dels.Product}}</td>
             
                  <td>{{dels.Quantity}}</td>
            
                  <td>R {{dels.CostOfProduct}}</td>
                   
              </tr>
          </table>
        </ng-container>  
    
        </div>
           
            
          
      </div>
      <div class="modal-footer">
        
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
</div>

 

  <!-- <div mdbModal #basicModal4="mdbModal" class="modal fade customDiv " tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true" >
    <div class="modal-dialog customModal" role="document">
      <div class="modal-content customModal">    
        <table class="table">
            <thead class="black white-text">
                <tr>
                  <th scope="col">Product</th>
                  <th scope="col">Qty</th>
                  <th scope="col">Price (R)</th>
                </tr>
              </thead>
              <tbody>
              <tr  *ngFor="let item of items">
                <td>{{item.Product}}</td>
                <td>x{{item.Quantity}}</td>
                <td>R {{item.CostOfProduct}}.00</td>
              </tr>
            </tbody>
        </table>     
        <img class="img-responsive" [src]="modalImage"/> 
        <div class="modal-footer customModal">
          <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal4.hide()" mdbWavesEffect>Close</button>
        </div>
      </div>
    </div>
  </div> -->


  <!--HTML for HELP MODAL. Load help message from database-->
<!-- <div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title w-100" id="myModalLabel" style="color:green">View Orders</h4>
        </div>
        <div class="modal-body">
          <p>This is the View Orders screen. It will list all your order. Here you can see some basic information on each order. You can also click on the "eye" icon next to an order to open a modal with additional information regarding the order.</p>
          <br/>
          <p style="color:red; font-weight: bold">You can only cancel orders in the "placed" status</p>
        </div>
        <div class="modal-footer">
          <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal.hide()" mdbWavesEffect>Close</button>
        </div>
      </div>
    </div>
  </div> -->
  <!--End of help modal-->

  
<div id="overlay">
  <span id="nav" *ngIf="saveInProgress">
      <div class="spinner" style="display: block; position: fixed">
      </div>
  </span>
  <span id="nav" *ngIf="saveInProgress">
      <div class="submit-message" style="display: block; color: #FFFFFF; position: fixed; left: 49.7%; top: 54.5%;">
          Requesting...
      </div>
  </span>
</div>
