
<header>
  <!-- navigation -->
  <div class="main-top py-1">
    <nav class="navbar navbar-expand-lg navbar-light fixed-navi">
      <div class="container">
        <!-- logo -->
        <!-- <a class="navbar-brand font-weight-bold" href="index.html">
          <i class="fa fa-apple mr-2" aria-hidden="true"></i>Fruitage
        </a> -->

        <a class="navbar-brand font-weight-bold"  routerLink="/index">
          <img src="assets/images/logo2.png" style="   height:100px;" alt="">
      </a>


        <!-- //logo -->
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
         aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse text-center" id="navbarSupportedContent">
          <ul class="navbar-nav ml-lg-auto">
            <li class="nav-item ">
              <a class="nav-link" href="index">Home
                <span class="sr-only">(current)</span>
              </a>
            </li>
            <li class="nav-item mx-lg-4 my-lg-0 my-3">
              <a class="nav-link"routerLink="/about" >About Us</a>
            </li>
            <li class="nav-item mx-lg-4 my-lg-0 my-3">
              <a class="nav-link" routerLink="/product">Products</a>
            </li>
        
            <li class="nav-item ml-lg-4 mt-lg-0 mt-3">
              <a class="nav-link" routerLink="/contact">Contact Us</a>
			</li>
			<li class="nav-item ml-lg-4 mt-lg-0 mt-3">
				<a class="nav-link" routerLink="/account">My Account</a>
			  </li>
			<li class="nav-item ml-lg-4 mt-lg-0 mt-3"><a class="nav-link waves-light"  *ngIf="gVars.isLoggedIn==false" routerLink="/login">Login</a></li>
			<li class="nav-item ml-lg-4 mt-lg-0 mt-3"><a class="nav-link waves-light"  *ngIf="gVars.isLoggedIn" routerLink="/product" (click)="logout()">Logout</a></li>
			<li class="nav-item ml-lg-4 mt-lg-0 mt-3"><a class="nav-link waves-light"  *ngIf="gVars.isLoggedIn==false" routerLink="/register">Register</a></li>
  
			<li class="nav-item ml-lg-4 mt-lg-0 mt-3">
				<!-- <mat-icon routerLink="/shoppingcart">shopping_basket</mat-icon> -->
				<!-- <span routerLink="/shoppingcart"></span> -->
				<a class="nav-link  material-icons icons"  style="font-size: 20px; vertical-align: text-bottom; height: auto;	" routerLink="/basket">shopping_basket</a>

			  </li>
    
          </ul>
        </div> 
      </div>
    </nav>
  </div>
  <!-- //navigation -->
</header>

<router-outlet></router-outlet>


	<!-- footer -->
	<footer class="footer-emp-w3ls py-sm-5 py-4">
		<div class="container py-xl-5 py-lg-3">
			<div class="row footer-top">
				<div class="col-lg-3 col-sm-6 footer-grid-wthree">
					<h1 class="footer-title text-uppercase text-white mb-lg-5 mb-4">Who We Are</h1>
					<div class="contact-info">
						<p style="text-align: justify;">Pfusheo is a recently established healthy foods venture that specializes in cooked muroho. We supply Muroho throughtout the whole of South Africa</p>
						<a href="about.html" class="btn button-style mt-sm-4 mt-3">About Us</a>
					</div>
				</div>
				<div class="col-lg-3 col-sm-6 footer-grid-wthree mt-sm-0 mt-5">
					<h3 class="footer-title text-uppercase text-white mb-lg-5 mb-4">Products</h3>
					<div class="contact-info">
						<div class="footer-style-w3ls">
							<h4 class="text-light mb-2">Muxe</h4>
						</div>
						<div class="footer-style-w3ls my-4">
							<h4 class="text-light mb-2">Thanga</h4>
						</div>
						<div class="footer-style-w3ls">
							<h4 class="text-light mb-2">Mushidzhi</h4>
						</div>
						
					</div>
				</div>
				<div class="col-lg-3 col-sm-6 footer-grid-wthree mt-lg-0 mt-5">
					<h3 class="footer-title text-uppercase text-white mb-lg-5 mb-4">Contact Us</h3>
					<div class="contact-info">
						<div class="footer-style-w3ls">
							<h4 class="text-light mb-2">Phone</h4>
							<p>081 564 7101</p>
						</div>
						<div class="footer-style-w3ls my-4">
							<h4 class="text-light mb-2">Email </h4>
							<p><a href="mailto:info@example.com">info@pfusheo.co.za</a></p>
						</div>
						<div class="footer-style-w3ls">
							<h4 class="text-light mb-2">Location</h4>
							<p>Thohoyandou, Limpopoo</p>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-sm-6 footer-grid-wthree mt-lg-0 mt-5">
					<h2 class="footer-title text-white mb-lg-5 mb-4">Quick Links</h2>
					<ul class="links list-unstyled">
						<li>
							<a href="index" >Home</a>
						</li>
						<li>
							<a href="about" >About Us</a>
						</li>
						<!-- <li>
							<a class="scroll" href="#works">Our Work</a>
						</li> -->
						<li>
							<a href="/product" >Products</a>
						</li>
						<li>
							<a href="/contact" class="m-0">Contact Us</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="copy-right-top border-top mt-5">
			<p class="copy-right text-center pt-xl-5 pt-4">
        
        
        Copyright &copy; 2020 All rights reserved | Design by <a href="http://odneth.co.za/">Odneth</a>

			</p>
		</div>
	</footer>
	<!-- //footer -->