import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../api.service';
import { FormBuilder, Validators,FormGroup } from '@angular/forms';
import { Basket_Line } from 'src/classes/basket_line';
import { GlobalvariablesService } from '../globalvariables.service';

@Component({
  selector: 'app-productdetail',
  templateUrl: './productdetail.component.html',
  styleUrls: ['./productdetail.component.css']
})
export class ProductdetailComponent implements OnInit {
  name:string;
  allProducts: any;
  constructor(private apiServices : ApiService,private route:ActivatedRoute, private cdRef : ChangeDetectorRef,private formbulider: FormBuilder,private gVars:GlobalvariablesService) { }



 
  loading : Boolean;

  products : any = [];
  constProducts : any = [];
  pages : any = [];
  currentPage=1;
  amountOfPages=0;
  imgStr:string;
  start:number;
  end:number;
  inBasket=false; 

  pageProducts:any = [];
  count=0;
  notLoggedIn=false;
  addedNewProduct=false;

  yeet=1;
  basketObj = new Basket_Line();
  productForm: any; 

  basket:Basket_Line[];
  basketData = false; 
  showModalBox: boolean = false;
  productId=0;

  ngOnInit(){

    this.route.params.subscribe(params => {
      this.name = params['name'];
    });
    this.loadAllProducts();
    this.gotoTop();
    this.productForm = this.formbulider.group({  
      Quantity: ['', [Validators.required]],  
      ProductID: ['', [Validators.required]],  
      Price: ['', [Validators.required]], 
      
     
    });


  }
  
  loadAllProducts() {  
   
    this.allProducts = this.apiServices.SearchProducts().subscribe(res=>this.allProducts=res);

 }
  gotoTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }



  onSubmit(){
    this.basketData = false;  
    const productData = this.productForm.value;  
    this.addItemsToBasket(productData);  
    this.productForm.reset();  
  
  
  
   }





   addItemsToBasket(productData){
    if(this.gVars.getLoggedInStatus())
    {
      this.inBasket=false;
      this.basketObj.BasketID=this.gVars.getBasketID();
      this.basketObj.ProductID= productData.id;
     debugger;
      this.basketObj.Quantity= productData.quantity;
      this.basketObj.Cost=(productData.price* productData.quantity)
      this.addedNewProduct=true;
      debugger;
      this.apiServices.addToBasket(this.basketObj).subscribe(r => {
  
        console.log(r);
        JSON.stringify(r);
      }, err =>  {
        if(err.error=="already in basket")
        {
          this.inBasket=true;
          this.addedNewProduct=false;
          
          this.gotoTop();
          
        }
      })
      this.yeet=1;
  
      
    }else{
      this.notLoggedIn=true;
      this.gotoTop();
      this.yeet=1;
    }
    this.yeet=1;
  }
  
    



}
