<!--HTML buttons for the menu of options regarding client side queries-->
<div class="container" style="text-align: center">
    <!--This is the help icon, when clicked, modal pops up-->
      <div class="card">
      <div style="padding:15px;">
          <div style="padding-bottom:15px">
              <button type="button" mdbBtn block="true" color="dark-green" mdbWavesEffect routerLink="/viewqueries">VIEW MY QUERIES</button>
          </div>
          <div style="padding-bottom:15px">
              <button type="button" mdbBtn block="true" color="dark-green" mdbWavesEffect routerLink="/clientquery">NEED HELP? SUBMIT A NEW QUERY!</button>
          </div>
          <div >
              <button type="button" mdbBtn block="true" color="dark-green" mdbWavesEffect routerLink="/account">BACK</button>
          </div>
      </div>
    </div>
  </div>
  <!--End of qquery menu-->
  
  <!--HTML for HELP MODAL. Load help message from database-->
  <div  class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close pull-right" aria-label="Close" >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title w-100" id="myModalLabel" style="color:green">Query Menu</h4>
          </div>
          <div class="modal-body">
            <p>This is the Query Menu.</p><br/>
            <p style="color:green; font-weight: bold">Here you can select to:</p>
            <p>-Click VIEW MY QUERIES to review your previous queries</p>
            <p>-Click SUBMIT A NEW QUERY to query us about something new</p>
            <p>-Click BACK to navigate back to the Account Menu</p>
          </div>
          <div class="modal-footer">
            <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close"  mdbWavesEffect>Close</button>
          </div>
        </div>
      </div>
    </div>
    <!--End of help modal-->