<!--The following HTML is for the registration form-->
<!-- Default form register -->
 <!--This is the help icon, when clicked, modal pops up-->
<form class="text-left border border-light p-5" style="background-color:#e8f5e9; padding:80px; margin-bottom:150px;"> 
    <p class="h4 mb-4">General Details</p> 
    <div class="form-row mb-4">
        <div class="col">
            <label>First Name</label>
            <input type="text" id="firstName" name="fName" class="form-control" placeholder="First name" (change)="valInput('firstName')" [(ngModel)]="fNameVar" >
            <div *ngIf="invFN" class="alet alert-danger">No Numbers or special characters</div>
        </div>
        <div class="col">
            <label>Last Name</label>
            <input type="text" id="lastName" name="lName" class="form-control" placeholder="Last name" (change)="valInput('lastName')" [(ngModel)]="lNameVar">
            <div *ngIf="invLN" class="alet alert-danger">No Numbers or special characters</div>
        </div>
    </div>
    <div class="form-row mb-4">
        <div class="col">
            <label>Phone Number</label>
            <input type="text" id="phoneNumber" name="pNumber" class="form-control" placeholder="Phone number" (change)="valInput('phoneNumber')"  [(ngModel)]="pNumberVar" aria-describedby="defaultRegisterFormPhoneHelpBlock">
            <div *ngIf="invCellN" class="alet alert-danger">No spaces and no code like +27</div>                  
        </div>
        <div class="col">
            <label>Email</label>
            <input type="email" id="email" name="eMail" class="form-control" placeholder="E-mail"  (change)="valInput('email')" [(ngModel)]="eMailVar">      
            <div *ngIf="invEM" class="alet alert-danger">Must include an @ and no special characters</div>
        </div>
    </div>
    <p class="h4 mb-4">Address Details</p> 
    <div class="form-row mb-4">
        <div class="col">
            <label>Street Name</label>
            <input type="text" id="streetName" name="sName" class="form-control"  placeholder="Street Name" (change)="valInput('streetName')" [(ngModel)]="sNameVar">
            <div *ngIf="invSN" class="alet alert-danger">No Numbers or special characters</div>                   
        </div>   
        <div class="col">
            <label>Street Number</label>
            <input type="text" id="streetNumber" name="sNumber" class="form-control" placeholder="Street Number" (change)="valInput('streetNumber')" [(ngModel)]="sNumberVar">
            <div *ngIf="invSNo" class="alet alert-danger">Number only</div> 
        </div>   
    </div>
    <div class="form-row mb-4">
        <div class="col">
            <label>Postal Code</label>
            <input type="text" id="postalCode" name="pCode" class="form-control" placeholder="Postal Code" (change)="valInput('postalCode')"  [(ngModel)]="pCodeVar">                         
            <div *ngIf="invPC" class="alet alert-danger">Number only</div> 
        </div>
        <div class="col">
            <label>Province</label>
            <select name="province" id="province" name="province" class="form-control mb-4" [(ngModel)]="provinceVar">
                <option value="Eastern Cape">Eastern Cape</option>
                <option value="Free State">Free State</option>
                <option value="Gauteng">Gauteng</option>
                <option value="Kwazulu Natal">Kwazulu Natal</option>
                <option value="Mpumalanga">Mpumalanga</option>
                <option value="Limpopo">Limpopo</option>          
                <option value="North West">North West</option>
                <option value="Northern Cape">Northern Cape</option>
                <option value="Western Cape">Western Cape</option>           
            </select>                      
        </div>
    </div>
    <div *ngIf="notAllFields" class="alet alert-danger">All fields are required!</div>  
    <button mdbBtn color="dark-green" class="my-4" type="submit" (click)="saveDetails()">Save</button>
    <button mdbBtn color="dark-green" class="my-4" type="submit" routerLink="/account">Back</button>
    <div *ngIf="showAlert" class="alert alert-danger">
            Update Failed! Please Retry.
    </div>
    <div *ngIf="updateSuccess" class="alert alert-success">
            Information Saved!
            <button mdbBtn color="dark-green" class="my-4" type="submit" routerLink="/account">My Account</button>
    </div>
  </form>

  
  <!--HTML for HELP MODAL. Load help message from database-->
<div  class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close pull-right" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
              <h4 class="modal-title w-100" id="myModalLabel" style="color:green">Edit Account Details</h4>
            </div>
            <div class="modal-body">
                    <p>This is the Edit My Account Details screen.</p><br/>
                    <p style="color:green; font-weight: bold"> The following apply:</p>
                    <p>-All fields are required</p>
                    <p>-Name and Last name may only contains letters</p>
                    <p>-Username may contains letters and numbers</p>
                    <p>-Passwords need to match and can contain any letters, numbers and special characters</p>
                    <p>-Street Name can only contains letters</p>
                    <p>-Street Number and Postal Code can only contains letters</p>
                    <p>-Phone number must be length 10 (no spaces) and no area code</p>
            </div>
            <div class="modal-footer">
              <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close"  mdbWavesEffect>Close</button>
            </div>
          </div>
        </div>
      </div>
      <!--End of help modal-->