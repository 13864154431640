import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { GlobalvariablesService } from '../globalvariables.service';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-viewqueries',
  templateUrl: './viewqueries.component.html',
  styleUrls: ['./viewqueries.component.css']
})
export class ViewqueriesComponent implements OnInit {

  constructor( private router:Router, private gVars:GlobalvariablesService, private apiService : ApiService) { }

  Queries:any=[];
  pop:any=[];
  current:number=0;
  contains=true;
  showEmpty=false;

  async  ngOnInit() {
    await this.apiService.SearchQueries().toPromise().then(x => {
      //this.Queries = x; 
      this.Queries=x;
      this.populateReal();
      //this.setData(x);
      //console.log(JSON.stringify(x));
      
    })
    
  }

  setData(dataX:any)
  {
    this.Queries = dataX;
    if(this.Queries.length==0)
    {
      this.contains=false;
      this.showEmpty=true;
    }
  }

  populateReal()
  {
    for(let x=0 ; x<this.Queries.length ;x++)
    {
      if(this.Queries[x].ClientID == this.gVars.getClientID())
      {
        this.pop[this.current]=this.Queries[x];
        this.current=this.current+1;
      }
    }
    console.log(this.pop);
    this.setData(this.pop);
  }
}
