
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';



// import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { ShopComponent } from './shop/shop.component';
// import { NavbarComponent } from './navbar/navbar.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
// import { StorelocatorComponent } from './storelocator/storelocator.component';
// import { AccountComponent } from './account/account.component';
// import { ShoppingcartComponent } from './shoppingcart/shoppingcart.component';
// import { ClientqueryComponent } from './clientquery/clientquery.component';
// import { QuerymenuComponent } from './querymenu/querymenu.component';
// import { RegisterComponent } from './register/register.component';
// import { LoginComponent } from './login/login.component';
import { HttpClientModule } from '@angular/common/http';
// import { ViewordersComponent } from './vieworders/vieworders.component';
// import { EditaccountdetailsComponent } from './editaccountdetails/editaccountdetails.component';
// import { ConfirmItemRemoveComponent } from './confirm-item-remove/confirm-item-remove.component';
// import { CheckoutComponent } from './checkout/checkout.component';
// import { ViewqueriesComponent } from './viewqueries/viewqueries.component'; 
import { AgmCoreModule } from '@agm/core';
import { ProductComponent } from './product/product.component';
import { BasketComponent } from './basket/basket.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { AccountComponent } from './account/account.component';
import { VerifyComponent } from './verify/verify.component';
import { PaysuccessComponent } from './paysuccess/paysuccess.component';
import { PaycancelComponent } from './paycancel/paycancel.component';
import { ViewordersComponent } from './vieworders/vieworders.component';
import { EditaccountdetailsComponent } from './editaccountdetails/editaccountdetails.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { QuerymenuComponent } from './querymenu/querymenu.component';
import { ViewqueriesComponent } from './viewqueries/viewqueries.component';
import { ClientqueryComponent } from './clientquery/clientquery.component';
import { ProductdetailComponent } from './productdetail/productdetail.component';
// import { PaysuccessComponent } from './paysuccess/paysuccess.component';
// import { PaycancelComponent } from './paycancel/paycancel.component';
// import { GuidevideoComponent } from './guidevideo/guidevideo.component';
// import { VerifyComponent } from './verify/verify.component';
// import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
// import { TcsComponent } from './tcs/tcs.component';

@NgModule({
  declarations: [
    AppComponent,
    // ShopComponent,
    // NavbarComponent,
    AboutComponent,
    ContactComponent,
    ProductComponent,
    BasketComponent,
    LoginComponent,
    RegisterComponent,
    BasketComponent,
    CheckoutComponent,
    AccountComponent,
    VerifyComponent,
    PaysuccessComponent,
    PaycancelComponent,
    ViewordersComponent,
    EditaccountdetailsComponent,
    ResetpasswordComponent,
    QuerymenuComponent,
    ViewqueriesComponent,
    ClientqueryComponent,
    ProductdetailComponent
 
    
    // StorelocatorComponent,
    // AccountComponent,
    // ShoppingcartComponent,
    // ClientqueryComponent,
    // QuerymenuComponent,
    // RegisterComponent,
    // LoginComponent,
    // ViewordersComponent,
    // EditaccountdetailsComponent,
    // ConfirmItemRemoveComponent,
    // CheckoutComponent,
    // ViewqueriesComponent,
    // PaysuccessComponent,
    // PaycancelComponent,
    // GuidevideoComponent,
    // VerifyComponent,
    // ResetpasswordComponent,
    // TcsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // MDBBootstrapModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    CommonModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey : 'AIzaSyAbObKET0O9IT5UjdEoXs9NrLL7pzWK6FY'
    })
    //HttpModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { 
  
}
    