	<!-- banner -->
	<div class="slider">
		<!-- Slideshow -->
		<ul class="rslides" id="slider">
			<li>
				<div class="slider-info banner-1">
					<div class="container">
						<div class="style-banner">
							<h3 class="text-white font-weight-bold">Prusheo</h3>
							<p class="text-light mt-sm-3 mt-2">Pushi dza Pfusheo, Where your health </p>
							<a href="about" class="btn button-style mt-sm-5 mt-4">Read More</a>
						</div>
					</div>
				</div>
			</li>
			<li>
				<div class="slider-info banner-2">
					<div class="container">
						<div class="style-banner">
							<h3 class="text-white font-weight-bold">A First of It's Kind</h3>
							<p class="text-light mt-sm-3 mt-2">Pfushep is only producers of frozen Muroho throught the country world. This extenders the life span of Muroho by up to 6 months</p>
							<a href="about" class="btn button-style mt-sm-5 mt-4">Read More</a>
						</div>
					</div>
				</div>
			</li>
			<li>
				<div class="slider-info banner-3">
					<div class="container">
						<div class="style-banner">
							<h3 class="text-white font-weight-bold">Some More stuff</h3>
							<p class="text-light mt-sm-3 mt-2">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
								deserunt mollit anim id est laborum.</p>
							<a href="about" class="btn button-style mt-sm-5 mt-4">Read More</a>
						</div>
					</div>
				</div>
			</li>
		</ul>
	</div>
	<!-- //banner -->

	<!-- banner bottom -->
	<div class="w3ls-welcome py-5">
		<div class="container py-xl-5 py-lg-3">
			<div class="row">
				<div class="col-lg-6 welcome-right p-lg-0 mt-lg-3 text-lg-left text-center">
					<img src="assets/images/about.png" alt=" " class="img-fluid">
				</div>
				<div class="col-lg-6 welcome-left mt-4 p-lg-0">
					<h3 class="text-dark font-weight-bold">Welcome To The Home of Pfusheo</h3>
					<h4 class="mb-sm-5 mb-4 mt-3 font-italic">Fruitage is a new look and eye-catching Awesome Theme for Agriculture
						Websites.
					</h4>
					<div class="row support-bottom">
						<div class="col-sm-6 support-grid">
							<div class="icon-effect grid-w3ls-2">
								<i class="fa fa-bus" aria-hidden="true"></i>
							</div>
							<h5 class="text-dark mt-4 mb-3">Free Shipping</h5>
							<p>Ut enim ad minima ven aiam, quis no strum ullam corp oris susc ipit.</p>
							<a href="about" class="btn button-style-2 text-uppercase mt-sm-4 mt-3 p-0">Read More<i class="fa fa-caret-right ml-2"
								 aria-hidden="true"></i></a>
						</div>
						<div class="col-sm-6 support-grid mt-sm-0 mt-5">
							<div class="icon-effect grid-w3ls-2">
								<i class="fa fa-refresh" aria-hidden="true"></i>
							</div>
							<h5 class="text-dark mt-4 mb-3">Free Refund</h5>
							<p>Ut enim ad minima ven aiam, quis no strum ullam corp oris susc ipit.</p>
							<a href="about" class="btn button-style-2 text-uppercase mt-sm-4 mt-3 p-0">Read More<i class="fa fa-caret-right ml-2"
								 aria-hidden="true"></i></a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- //banner bottom -->

	<!-- laptop section -->
	<div style="margin-top: -100px;" class="who-agile text-center">
		<div class="wrap py-xl-5 py-lg-3">
			<!-- <div class="style-agile-2 py-5 px-4">
				<img src="assets/images/lap.png" alt="" class="img-fluid">
			</div> -->
			<div class="title-midd-w3ls py-5">
				<h3 class="tittle titlem-2 font-weight-bold text-white">We Sell Cooked Organic Muroho</h3>
				<a href="about" class="btn button-style button-style-3 mt-sm-4 mt-3">Read More</a>
			</div>
			<div class="row style-agile">
				<div class="col-md-4 exce-grid1-mmstyle">
					<div class="grid-ec1 bg-white py-5 px-4">
						<img src="assets/images/f1.jpg" alt="" class="img-fluid">
						<h4 class="mt-3">Fresh, tasty Strawberry</h4>
					</div>
				</div>
				<div class="col-md-4 exce-grid1-mmstyle my-md-0 my-4">
					<div class="grid-ec1 bg-white py-5 px-4">
						<img src="assets/images/f2.png" alt="" class="img-fluid">
						<h4 class="mt-3">The orange juice test</h4>
					</div>
				</div>
				<div class="col-md-4 exce-grid1-mmstyle">
					<div class="grid-ec1 bg-white py-5 px-4">
						<img src="assets/images/f3.jpg" alt="" class="img-fluid">
						<h4 class="mt-3">Green & Black Grapes</h4>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- //laptop section -->

	<!-- mobile section -->
	<!-- <div class="feature py-5" id="what">
		<div class="container py-xl-5 py-lg-3">
			<div class="row">
				<div class="col-xl-6 col-lg-8 mt-4">
					<h3 class="text-dark font-weight-bold text-mobile-w3">Big Sale up to 30% off on all products</h3>
					<p class="white-forming my-xl-4 my-3 mb-md-0 mb-5">Praesent congue iaculis pulvinar. Praesent sit amet consequat
						sem, nec scelerisque
						nisl.</p>
					<div class="agileits-w3layouts-grid pt-md-4">
						<div class="row wthree_agile_us">
							<div class="col-lg-3 col-sm-2 col-3 text-center">
								<div class="icon-effect grid-w3ls-2">
									<i class="fa fa-apple" aria-hidden="true"></i>
								</div>
							</div>
							<div class="col-lg-9 col-sm-10 col-9 agile-why-text">
								<h4 class="text-dark mb-3">Fresh Fruits</h4>
								<p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.</p>
							</div>
						</div>
						<div class="row wthree_agile_us my-xl-5 my-lg-4 my-sm-5 my-4">
							<div class="col-lg-3 col-sm-2 col-3 text-center">
								<div class="icon-effect grid-w3ls-2">
									<i class="fa fa-user-secret" aria-hidden="true"></i>
								</div>
							</div>
							<div class="col-lg-9 col-sm-10 col-9 agile-why-text">
								<h4 class="text-dark mb-3">Support 24/7</h4>
								<p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.</p>
							</div>
						</div>
						<div class="row wthree_agile_us">
							<div class="col-lg-3 col-sm-2 col-3 text-center">
								<div class="icon-effect grid-w3ls-2">
									<i class="fa fa-retweet" aria-hidden="true"></i>
								</div>
							</div>
							<div class="col-lg-9 col-sm-10 col-9 agile-why-text">
								<h4 class="text-dark mb-3">Free Exchange</h4>
								<p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.</p>
							</div>
						</div>
					</div>
				</div>
				<div class="col-xl-2 order-xl-1 order-2"></div>
				<div class="col-lg-4 order-xl-2 order-1 mt-lg-0 mt-5">
					<div class="phone text-center">
						<img alt="" src="assets/images/iphone.png" class="img-fluid phone-img-w3ls">
					</div>
				</div>
			</div>
		</div>
	</div> -->
	<!-- //mobile section -->

	<!-- testimonials -->
	<!-- <section class="clients py-5" id="testi">
		<div class="container py-xl-5 py-lg-3">
			<div class="row py-sm-5">
				<div class="col-lg-6 col-md-8">
					<div id="carouselExampleIndicators" class="carousel slide pb-5" data-ride="carousel">
						<ol class="carousel-indicators">
							<li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
							<li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
							<li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
						</ol>
						<div class="carousel-inner">
							<div class="carousel-item active">
								<div class="feedback-info">
									<div class="feedback-top">
										<p>Lorem ipsum dolor sit amet consectetur adipisicing elit sedc dnmo eiusmod. sed do eiusmod tempor
											incididunt
											ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi</p>
										<h4 class="mt-4 text-white font-weight-bold mb-4">Mary Jane</h4>
									</div>
								</div>
							</div>
							<div class="carousel-item">
								<div class="feedback-info">
									<div class="feedback-top">
										<p>Lorem ipsum dolor sit amet consectetur adipisicing elit sedc dnmo eiusmod. sed do eiusmod tempor
											incididunt
											ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi</p>
										<h4 class="mt-4 text-white font-weight-bold mb-4">Steven Wison</h4>
									</div>
								</div>
							</div>
							<div class="carousel-item">
								<div class="feedback-info">
									<div class="feedback-top">
										<p>Lorem ipsum dolor sit amet consectetur adipisicing elit sedc dnmo eiusmod. sed do eiusmod tempor
											incididunt
											ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi</p>
										<h4 class="mt-4 text-white font-weight-bold mb-4">Mary Jane</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6 col-md-4"></div>
			</div>
		</div>
	</section> -->
	<!-- //testimonials -->

	<!-- stats -->
	<!-- <section class="team-sec main-stats-inner py-5" id="stats">
		<div class="container py-xl-5 py-lg-3">
	
			<div class="main-title text-center mb-5 pb-3">
				<h3 class="tittle text-dark font-weight-bold">Title Here</h3>
				<p class="sub-tittle mt-2">Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
					veniam,
					quis nostrud exercitation</p>
			</div>
		
			<div class="inner-sec-w3ls-agileits">
				<div class="row stats-con text-center border">
					<div class="col-md-3 col-6 stats_info counter_grid p-0">
						<i class="fa fa-external-link" aria-hidden="true"></i>
						<p class="counter font-weight-bold">645</p>
						<h4>Projects Done</h4>
					</div>
					<div class="col-md-3 col-6 stats_info counter_grid1 p-0">
						<i class="fa fa-heart-o" aria-hidden="true"></i>
						<p class="counter font-weight-bold">563</p>
						<h4>Satisfied Clients</h4>
					</div>
					<div class="col-md-6 main-stats-inner-img">

					</div>
					<div class="col-md-6 main-stats-inner-img two">

					</div>
					<div class="col-md-3 col-6 stats_info counter_grid p-0">
						<i class="fa fa-magic" aria-hidden="true"></i>
						<p class="counter font-weight-bold">1145</p>
						<h4>Awards</h4>
					</div>
					<div class="col-md-3 col-6 stats_info counter_grid2 p-0">
						<i class="fa fa-smile-o" aria-hidden="true"></i>
						<p class="counter font-weight-bold">1045</p>
						<h4>Happy Clients</h4>
					</div>
				</div>
			</div>
		</div>
	</section> -->
	<!-- //stats -->

	<!-- services -->
	<!-- <div class="serives-agile py-5" id="services">
		<div class="container py-xl-5 py-lg-3">
			<div class="row support-bottom text-center">
				<div class="col-md-4 services-w3ls-grid">
					<i class="fa fa-bullhorn" aria-hidden="true"></i>
					<h4 class="text-white text-uppercase mt-md-5 mt-4 mb-3">Heading Here</h4>
					<p>Ut enim ad minima veniam, quis nostrum ullam corporis suscipit laboriosam.</p>
				</div>
				<div class="col-md-4 services-w3ls-grid my-md-0 my-4">
					<i class="fa fa-cubes" aria-hidden="true"></i>
					<h4 class="text-white text-uppercase mt-md-5 mt-4 mb-3">Heading Here</h4>
					<p>Ut enim ad minima veniam, quis nostrum ullam corporis suscipit laboriosam.</p>
				</div>
				<div class="col-md-4 services-w3ls-grid">
					<i class="fa fa-shopping-basket" aria-hidden="true"></i>
					<h4 class="text-white text-uppercase mt-md-5 mt-4 mb-3">Heading Here</h4>
					<p>Ut enim ad minima veniam, quis nostrum ullam corporis suscipit laboriosam.</p>
				</div>
			</div>
		</div>
	</div> -->
	<!-- //services -->


	<!-- <section class="works-agiles py-5" id="work">
		<div class="container py-xl-5 py-lg-3">

			<div class="main-title text-center mb-5 pb-3">
				<h3 class="tittle text-dark font-weight-bold">Our Work</h3>
				<p class="sub-tittle mt-2">Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
					veniam,
					quis nostrud exercitation</p>
			</div>

			<div class="price-right">
				<div class="tabs">
					<ul class="nav nav-pills mb-5" id="pills-tab" role="tablist">
						<li class="nav-item">
							<a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home"
							 aria-selected="true">Category 1</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile"
							 aria-selected="false">Category 2</a>
						</li>
					</ul>
					<div class="tab-content" id="pills-tabContent">
						<div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
							<div class="menu-grids">
								<div class="row">
									<div class="col-lg-6 price-main-info">
										<div class="tabs-sect-w3ls mb-sm-5 mb-4">
											<h3 class="text-dark font-weight-bold text-mobile-w3-2">Category 1</h3>
											<h4 class="mt-3 font-italic dtyle-title">Doloremque laudantium, totam rem aperiam, eaqu sit amet consequat
												sem.</h4>
										</div>
										<div class="progress-one">
											<h4 class="progress-tittle">Fresh Fruits</h4>
											<div class="progress">
												<div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 95%"
												 aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
										</div>
										<div class="progress-one two-progress">
											<h4 class="progress-tittle">Tasty Fruits</h4>
											<div class="progress">
												<div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 88%"
												 aria-valuenow="88" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
										</div>
										<div class="progress-one">
											<h4 class="progress-tittle">Healthy Fruits</h4>
											<div class="progress">
												<div class="progress-bar progress-bar-striped bg-info" role="progressbar" style="width: 80%" aria-valuenow="80"
												 aria-valuemin="0" aria-valuemax="100"></div>
											</div>
										</div>
									</div>
									<div class="col-lg-6 price-main-info-2 mt-lg-0 mt-5">
										<img src="assets/images/farmer2.jpg" alt="" class="img-fluid">
									</div>
								</div>
							</div>
						</div>
						<div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
							<div class="menu-grids">
								<div class="row">
									<div class="col-lg-6 price-main-info-2">
										<img src="assets/images/farmer1.jpg" alt="" class="img-fluid">
									</div>
									<div class="col-lg-6 price-main-info mt-lg-0 mt-5">
										<div class="tabs-sect-w3ls mb-sm-5 mb-4">
											<h3 class="text-dark font-weight-bold text-mobile-w3-2">Category 2</h3>
											<h4 class="mt-3 font-italic dtyle-title">Doloremque laudantium, totam rem aperiam, eaqu sit amet consequat
												sem.</h4>
										</div>
										<div class="progress-one">
											<h4 class="progress-tittle">Fresh Fruits</h4>
											<div class="progress">
												<div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 95%"
												 aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
										</div>
										<div class="progress-one two-progress">
											<h4 class="progress-tittle">Tasty Fruits</h4>
											<div class="progress">
												<div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 88%"
												 aria-valuenow="88" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
										</div>
										<div class="progress-one">
											<h4 class="progress-tittle">Healthy Fruits</h4>
											<div class="progress">
												<div class="progress-bar progress-bar-striped bg-info" role="progressbar" style="width: 80%" aria-valuenow="80"
												 aria-valuemin="0" aria-valuemax="100"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section> -->