import { Injectable } from '@angular/core';
import { AppComponent } from './app.component';

@Injectable({
  providedIn: 'root'
})
export class GlobalvariablesService {
  // TODO: set the default to 0 when login is implemented
  public clientID = 0;
  public userID = 0;
  public basketID = 0;
  public clientTypeID = 1;
  public clientAddressID =0;
  public clientName ="";
  public clientSurname ="";
  public clientCellNumber="";
  public clientEmail="";
  public isLoggedIn = false;
  public verified = false;

  public streetName = "";
  public streetNumber = "";
  public postalCode = "";
  public province ="";

  public remID=0;
  public heading="";
  
  constructor() { }

  //logout function
  public logout() {
    this.clientID = 0;
    this.userID = 0;
    this.basketID = 0;
    this.clientTypeID = 1;
    this.clientAddressID =0;
    this.clientName ="";
    this.clientSurname ="";
    this.clientCellNumber="";
    this.clientEmail="";
    this.isLoggedIn=false;

    sessionStorage.clear();
    //location.href=location.href;
  }

 
  //ClientID
  public getClientID(): number{
    return this.clientID;
  }
  public setClientID( newV:number){
    this.clientID = newV;
  }
  //Verified
  public getVerified(){
    return this.verified;
  }
  public setVerified(status:boolean){
    this.verified=status;
  }
  //UserID
  public getUserID(): number{
    return this.userID;
  }
  public setUserID( newV:number){
    this.userID = newV;
  }
  //BasketID
  public getBasketID(): number{
    return this.basketID;
  }
  public setBaskettID( newV:number){
    this.basketID = newV;
  }
  //ClientTypeID
  public getClientTypeID(): number{
    return this.clientTypeID;
  }
  public setClientTypeID( newV:number){
    this.clientTypeID = newV;
  }
  //ClientAddressID
  public getClientAddressID(): number{
    return this.clientAddressID;
  }
  public setClientAddressID( newV:number){
    this.clientAddressID = newV;
  }
  //ClientName
  public getClientName(): string{
    return this.clientName;
  }
  public setClientName( newV:string){
    this.clientName = newV;
  }
  //ClientSurname
  public getClientSurname(): string{
    return this.clientSurname;
  }
  public setClientSurname( newV:string){
    this.clientSurname = newV;
  }
  //ClientCell
  public getClientCell(): string{
    return this.clientCellNumber;
  }
  public setClientCell( newV:string){
    this.clientCellNumber = newV;
  }
  //ClientEmail
  public getClientEmail(): string{
    return this.clientEmail;
  }
  public setClientEmail( newV:string){
    this.clientEmail = newV;
  }
  //isLoggedIn
  public getLoggedInStatus(): boolean{
    return this.isLoggedIn;
  }
  public setLoggedInStatus( newV:boolean){
    this.isLoggedIn = newV;
  }
  //streetName
  public getStreetName(): string{
    return this.streetName;
  }
  public setStreetName(newV:string){
    this.streetName=newV;
  }
  //streetNumber
  public getStreetNumber(): string{
    return this.streetNumber;
  }
  public setStreetNumber(newV:string){
    this.streetNumber=newV;
  }
  //postalCode
  public getPostalCode(): string{
    return this.postalCode;
  }
  public setPostalCode(newV:string){
    this.postalCode=newV;
  }
  //province
  public getProvince(): string{
    return this.province;
  }
  public setProvince(newV:string){
    this.province=newV;
  }
}
