import { Component, OnInit } from '@angular/core';
import { User } from '../../classes/user';
import { ApiService } from '../api.service';
import { GlobalvariablesService } from '../globalvariables.service';
import { Router, RouterModule, Routes } from '@angular/router';
import { AppComponent } from '../app.component';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public logUsernameVar = "";
  public logPasswordVar = "";

  constructor(private apiService: ApiService, private gVars:GlobalvariablesService, private router:Router, private appC:AppComponent) { }

  userObj = new User;
  succLogin = false;
  emptyUSR=false;
  emptyPSR=false;
  invPass = false;
  ee:boolean;

  ngOnInit() {
  }

  setAddressData(data){
    console.log(data);
    this.gVars.setStreetName(data.StreetName);
    this.gVars.setStreetNumber(data.StreetNumber);
    this.gVars.setPostalCode(data.PostalCode);
    this.gVars.setProvince(data.Province);
    sessionStorage.setItem('streetName',this.gVars.getStreetName());
    sessionStorage.setItem('streetNumber',this.gVars.getStreetNumber());
    sessionStorage.setItem('postalCode',this.gVars.getPostalCode());
    sessionStorage.setItem('province',this.gVars.getProvince());
  }

  getUserData(data) { 
    if(data.Message=="Invalid User!")
    {
      this.succLogin = true;
    }else if(data.Message=="Invalid Password!"){
      this.invPass = true;
    }else{
      this.gVars.setBaskettID(data.BasketID);
      this.gVars.setClientAddressID(data.ClientAddressID);
      this.gVars.setClientCell(data.ClientCellNumber);
      this.gVars.setClientEmail(data.ClientEmail);
      this.gVars.setClientID(data.ClientID);
      this.gVars.setClientName(data.ClientName);
      this.gVars.setClientSurname(data.ClientSurname);
      this.gVars.setClientTypeID(data.ClientTypeID);
      this.gVars.setUserID(data.UserID);
      this.gVars.setVerified(data.verified);

      sessionStorage.setItem('basketID',this.gVars.getBasketID().toString());
      sessionStorage.setItem('clientAddressID',this.gVars.getClientAddressID().toString());
      sessionStorage.setItem('clientCell',this.gVars.getClientCell());
      sessionStorage.setItem('clientEmail',this.gVars.getClientEmail());
      sessionStorage.setItem('clientID',this.gVars.getClientID().toString());
      sessionStorage.setItem('clientName',this.gVars.getClientName());
      sessionStorage.setItem('clientSurname',this.gVars.getClientSurname());
      sessionStorage.setItem('clientTypeID',this.gVars.getClientTypeID().toString());
      sessionStorage.setItem('userID',this.gVars.getUserID().toString());
      if(this.gVars.getVerified()==true)
      {
        sessionStorage.setItem('verified','true');
      }else{
        sessionStorage.setItem('verified','false');
      }

      if(this.gVars.getClientTypeID()==1)
      {
        //this.appC.setPrivate(true);
        this.gVars.heading = "Welcome " + this.gVars.getClientName() + "!";
      }
      if(this.gVars.getClientTypeID()==2)
      {
        //this.appC.setRetail(true);
        this.gVars.heading = "Welcome " + this.gVars.getClientName() + "! Retail Account";
      }

      //set logged in status
      this.gVars.setLoggedInStatus(true);
      sessionStorage.setItem('loggedInStatus',this.gVars.getLoggedInStatus().toString());
      this.succLogin = false;
      this.emptyPSR = false;
      this.emptyUSR = false;
      this.invPass = false;
      //get client address data
      this.apiService.getAddressData(this.gVars.getClientAddressID()).subscribe(r=> {
        this.setAddressData(r);
      })
      this.router.navigate(['/product']);
      //set global vars to session vars
      this.gVars.setBaskettID(+sessionStorage.getItem('basketID'));
      this.gVars.setClientAddressID(+sessionStorage.getItem('clientAddressID'));
      this.gVars.setClientCell(sessionStorage.getItem('clientCell'));
      this.gVars.setClientEmail(sessionStorage.getItem('clientEmail'));
      this.gVars.setClientID(+sessionStorage.getItem('clientID'));
      this.gVars.setClientName(sessionStorage.getItem('clientName'));
      this.gVars.setClientSurname(sessionStorage.getItem('clientSurname'));
      this.gVars.setClientTypeID(+sessionStorage.getItem('clientTypeID'));
      this.gVars.setUserID(+sessionStorage.getItem('userID'));
      this.gVars.setStreetName(sessionStorage.getItem('streetName'));
      this.gVars.setStreetNumber(sessionStorage.getItem('streetNumber'));
      this.gVars.setPostalCode(sessionStorage.getItem('postalCode'));
      this.gVars.setProvince(sessionStorage.getItem('province'));
      this.ee= (/true/i).test(sessionStorage.getItem('loggedInStatus'));
      this.gVars.setLoggedInStatus(this.ee);
    }
  }

  clientLogin()
  {
    this.succLogin=false;
    this.emptyPSR=false;
    this.emptyUSR=false;
    if(this.logPasswordVar=="" && this.logUsernameVar=="")
    {
      this.emptyPSR=true;
      this.emptyUSR=true;
      return;
    }
    if(this.logUsernameVar=="")
    {
      this.emptyUSR=true;
      return;
    }
    if(this.logPasswordVar=="")
    {
      this.emptyPSR=true;
      return;
    }

    this.userObj.UserName = this.logUsernameVar;
    this.userObj.UserPassword = this.logPasswordVar;
    console.log(JSON.stringify(this.userObj));

    this.apiService.LoginClient(this.userObj).subscribe(r=> {
      console.log(JSON.stringify(r));
      this.getUserData(r);
    })  

  }

  clearInput(f : string)
  {
    switch(f) { 
      case f = "logUsernameVar" : {
        this.logUsernameVar = "";
        break;
      }
      case f = "logPasswordVar" : {
        this.logPasswordVar = "";
        break;
      }
      default : {
        break;
      }
    }
    
  }
}
