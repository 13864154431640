import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, observable } from 'rxjs';
import { Client } from '../classes/client';
import { User } from '../classes/user';
import { ClientAddress } from '../classes/client-address';
import { exist } from 'src/classes/exist';
import { Basket_Line } from 'src/classes/basket_line';
import { Client_Query } from 'src/classes/Client_Query';
import { ContactUsEmail } from 'src/classes/contact-us-email';
import { Otp } from 'src/classes/otp';
import { VerifyOTP } from 'src/classes/verify-otp';
import { Newpass } from 'src/classes/newpass';



const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    "Access-Control-Allow-Origin": '*'
  })
};

const httpText = {
  headers: new HttpHeaders({
    'Content-Type':  'text/plain',
    "Access-Control-Allow-Origin": '*'
  })
};



@Injectable({
  providedIn: 'root'
})
export class ApiService {
  
  apiURL: string = 'http://test.lammed.co.za/api/'; // Todo add API URL

  constructor(private http : HttpClient) { }

  /*public userExists(usr : string){
    return this.http.post(this.apiURL+'Client/userExists?Username='+usr,usr,httpText);
  }*/

  public doesUserExist(usr:string){
    return this.http.get(this.apiURL+'Client/doesUserExist/'+usr);
  }

  public registerUser(UserObj: User){
    return this.http.post<User>(this.apiURL+'Client/registerNewUser',UserObj,httpOptions);
  }

  public registerClientAddress(ClientAddressObj: ClientAddress){
    return this.http.post<ClientAddress>(this.apiURL+'Client/registerNewClientAddress',ClientAddressObj, httpOptions);
  }

  public registerClient(ClientObj: Client){
    return this.http.post<Client>(this.apiURL+'Client/registerNewClient', ClientObj, httpOptions);
  }

  //add items to basket
  public addToBasket(BasketObj : Basket_Line){
    return this.http.post<Basket_Line>(this.apiURL+'Basket/AddItemsToBasket', BasketObj, httpOptions);
  }

  //add client query
  public addQuery(qObj: Client_Query){
    return this.http.post<Client_Query>(this.apiURL+'Client/AddQuery', qObj, httpOptions);
  }

  public updateClientAddress(id:number, ClientAddressObj: ClientAddress){
    return this.http.post(this.apiURL+'Client/UpdateClientAddress/'+id,ClientAddressObj);
  }

  public updateClient(id : number, ClientObj: Client){
    return this.http.post(this.apiURL+'Client/UpdateClient/'+id, ClientObj);
  }

  //get MD5 string for PayFast
  public getMD5(obj:any){
    return this.http.post(this.apiURL+'Client/getMD5Hash',obj,httpOptions);
  }

  //ask question on Contact Us email
  public contactUsEmail(emailInfo:ContactUsEmail) {
    return this.http.post(this.apiURL+'Client/SendClientQuestionEmail',emailInfo, httpOptions);
  }
  
  //resend OTP via email
  public resendOTP(resendInfo:Otp) {
    return this.http.post(this.apiURL+'Client/ResendOTP',resendInfo, httpOptions);
  }

  //request password reset
  public requestPasswordReset(usrn:string) {
    return this.http.post(this.apiURL+'Client/RequestPasswordReset/'+usrn,usrn, httpOptions);
  }

  //check enterer OTP for password reset
  public verifyResetOTP(dataX:VerifyOTP) {
    return this.http.post(this.apiURL+'Client/VerifyResetOTP',dataX, httpOptions);
  }

  //set new pass
  public setNewPass(pss:Newpass) {
    return this.http.post(this.apiURL+'Client/SetNewPass',pss, httpOptions);
  }

  //verify account with OTP
  public verifyWithOTP(otpObj:Otp) {
    return this.http.post(this.apiURL+'Client/VerifyWithOTP',otpObj, httpOptions);
  }

  //cancel order
  public cancelOrder(oID:number) {
    return this.http.get(this.apiURL+'Client/CancelOrder/'+oID);
  }

  //create order
  /*public createOrder(orderInfo : any)
  {
    return this.http.post(this.apiURL+'Vetter/VBasket/CreateOrder',orderInfo,httpOptions);
  }*/
  public createOrder(prod : any) {
    const endpoint = this.apiURL+'/Basket/CreateOrder';
    const formData: FormData = new FormData();
    formData.append('ClientID', prod.ClientID);
    formData.append('BasketID', prod.BasketID);
    formData.append('TotalCost', prod.TotalCost);
    formData.append('OrderDate', prod.OrderDate);
    formData.append('OrderType', prod.OrderType);
   return this.http.post(endpoint, formData);
  }

  public LoginClient(user : User) {
    return this.http.post<User>(this.apiURL+'Client/ClientLogin',user, httpOptions);
  }

  public getAddressData(cliAddID:number) {
    return this.http.get(this.apiURL+'Client/getAddressData/'+cliAddID);
  }

  //get Store Locator map markers
  public getMarkers() {
    return this.http.get(this.apiURL+'Client/getMarkers');
  }

  //Remove item from basket
  public removeBasketItem(id:number, bId:number) {
    return this.http.get(this.apiURL+'Basket/RemoveItem/'+id+'/'+bId);
  }

  //Show Basket APi
  public getBasketItemDetails(id : number) {
    return this.http.get(this.apiURL+'Basket/GetBasketItemDetails/'+id)
  }

  //load client's order
  public getOrders(id : number) {
    return this.http.get(this.apiURL+'Basket/ViewClientOrders/'+id, httpOptions)
  }
  //get queries
  public SearchQueries(){
    return this.http.get(this.apiURL+'/Employee/SearchQueries');
  }

  //-------------------load products for shop page-----------
  /*public getProducts() {
    return this.http.get(this.apiURL+'Vetter/VProduct/SearchProducts');
  }*/
  public SearchProducts() {
    return this.http.get(this.apiURL+'Product/SearchProducts');
  }

  //update basket_line
  public UpdateBasketQuantity(BLine : any){
    return this.http.post(this.apiURL+'Basket/UpdateQuantity', BLine);
  }
}


 /* public registerUser(user: User) : Observable<User> {
    return this.http.post<User>("http://localhost:50981/api/User/addUserLogin", user, httpOptions); // Todo add API path
  }*/






