import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
// import { Product } from '../classes/product';
import { ApiService } from '../api.service';
// import { ProductType } from '../Classes/product-type';
import { Observable } from 'rxjs';
import {Router} from '@angular/router'
import { Basket_Line } from 'src/classes/basket_line';
import { GlobalvariablesService } from '../globalvariables.service';
import { FormBuilder, Validators,FormGroup } from '@angular/forms';



@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

  constructor(private apiServices : ApiService, private gVars:GlobalvariablesService, private router:Router, private cdRef : ChangeDetectorRef,private formbulider: FormBuilder, ) { }
  
  allProducts: any;
  loading : Boolean;

  products : any = [];
  constProducts : any = [];
  pages : any = [];
  currentPage=1;
  amountOfPages=0;
  imgStr:string;
  start:number;
  end:number;
  inBasket=false; 

  pageProducts:any = [];
  count=0;
  notLoggedIn=false;
  addedNewProduct=false;

  yeet=1;
  basketObj = new Basket_Line();
  productForm: any; 

  basket:Basket_Line[];
  basketData = false; 
  showModalBox: boolean = false;
  productId=0;
  
  ngOnInit(){
    this.productForm = this.formbulider.group({  
      Quantity: ['', [Validators.required]],  
      ProductID: ['', [Validators.required]],  
      Price: ['', [Validators.required]], 
      
     
    });

    this.loadAllProducts();


  }

  loadAllProducts() {  
   
    this.allProducts = this.apiServices.SearchProducts().subscribe(res=>this.allProducts=res);

    


 }

 onSubmit(){
  this.basketData = false;  
  const productData = this.productForm.value;  
  this.addItemsToBasket(productData);  
  this.productForm.reset();  



 }

 gotoTop() {
  window.scroll({ 
    top: 0, 
    left: 0, 
    behavior: 'smooth' 
  });
}

public open(id) {
  if(0){
    // Dont open the modal
    
    this.showModalBox = false;
  } else {
     // Open the modal
this.productId=id;
     this.showModalBox = true;
  }
}


 addItemsToBasket(productData){
  if(this.gVars.getLoggedInStatus())
  {
    this.inBasket=false;
    this.basketObj.BasketID=this.gVars.getBasketID();
    this.basketObj.ProductID= productData.id;
   debugger;
    this.basketObj.Quantity= productData.quantity;
    this.basketObj.Cost=(productData.price* productData.quantity)
    this.addedNewProduct=true;
    debugger;
    this.apiServices.addToBasket(this.basketObj).subscribe(r => {

      console.log(r);
      JSON.stringify(r);
    }, err =>  {
      if(err.error=="already in basket")
      {
        this.inBasket=true;
        this.addedNewProduct=false;
        
        this.gotoTop();
        
      }
    })
    this.yeet=1;

    
  }else{
    this.notLoggedIn=true;
    this.gotoTop();
    this.yeet=1;
  }
  this.yeet=1;
}

  
 


}
