<!-- banner -->
<div class="banner-w3ls-2">

</div>
<!-- //banner -->
<!-- page details -->
<div class="breadcrumb-agile">
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a href="index.html">Home</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Products/ {{name}}</li>
    </ol>
</div>
<!-- //page details -->

<!-- gallery -->
<div class="gallery-agile py-5">
    <div class="container py-xl-5 py-lg-3">
        <!-- heading title -->
        <div class="main-title text-center mb-5 pb-3">
            <div *ngFor="let product of allProducts ">
                <ng-container *ngIf="product.ProductName==name">
                    
                   

                    <div class="row gallery-agile-kmsrow">
                        <div class="col-md-5 col-sm-12 gallery-agile-grids">
                            <div class="portfolio-hover">
                               
                                    <img [src]='product.ProductImage' class="img-fluid zoom-img" alt="" />
                                    <div class="name-agile-gallery d-none d-md-block">
                                        <form [formGroup]="productForm" (ngSubmit)="onSubmit()">  
                                            <input  class="w-100" style="width: 100px;" #quantity [hidden]="product.ProductQOH <1" type="number"   min="1" placeholder="Quantity" [max]="product.ProductQOH" formControlName="Quantity">
                                                <div ><button [disabled]="quantity.value==''" [hidden]="product.ProductQOH <1" class="btn btn-success w-100 border"  type="submit"  (click)="addItemsToBasket({id: product.ProductID, price: product.ProductRetailPrice,quantity: quantity.value}); quantity.value=''; ">Add to basket</button></div>
                            
                                            </form>
                                    </div>
                               
                            </div>
                        </div>
                        
            
            
                  <div class="col-md-7 col-sm-12 gallery-agile-grids">
                    <h3 class="tittle text-dark font-weight-bold">{{product.ProductName}}  <span style="font-size: 12px">{{product.ProductSize}}</span> </h3>
                    <div style="font-size: 20px">
                                                
                         R{{product.ProductRetailPrice}}.00

                     </div>
                       <p style="text-align: justify;" class="sub-tittle mt-2" >Description: <br>{{product.ProductDescription}}</p> <br> <br>
                     
                  </div>
                </div>

             
                <div class="footerAddtoBasket d-block d-md-none">
                    <form [formGroup]="productForm" (ngSubmit)="onSubmit()">  
                        <input  class="w-100" style="width: 100px;" #quantity [hidden]="product.ProductQOH <1" type="number"   min="1" placeholder="Quantity" [max]="product.ProductQOH" formControlName="Quantity">
                            <div ><button [disabled]="quantity.value==''" [hidden]="product.ProductQOH <1" class="btn btn-success w-100 border"  type="submit"  (click)="addItemsToBasket({id: product.ProductID, price: product.ProductRetailPrice,quantity: quantity.value}); quantity.value=''; ">Add to basket</button></div>
        
                        </form>
                  </div>
            </ng-container>
        </div>
        </div>
        <!-- //heading title -->





    </div>
</div>

