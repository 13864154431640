import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { GlobalvariablesService } from '../globalvariables.service';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-paysuccess',
  templateUrl: './paysuccess.component.html',
  styleUrls: ['./paysuccess.component.css']
})

export class PaysuccessComponent implements OnInit {

  constructor(private router:Router, private gVars:GlobalvariablesService, private apiService : ApiService, private http: HttpClient) { }

  order : any =[];
  totalPrice=0;
  BasketProducts : any = [];
  postDataFromPayFast : any;

  async ngOnInit() {

    await this.apiService.getBasketItemDetails(this.gVars.getBasketID()).toPromise().then(data => {
      this.BasketProducts = data;
      this.generateOrder();
   })
  }

  getTotal(){
    for( let x=0; x<this.BasketProducts.length; x++)
    {
      this.totalPrice += this.BasketProducts[x].Cost;
    }
  }

  generateOrder()
  {
    this.getTotal();
    this.order.ClientID = this.gVars.getClientID();
    this.order.BasketID = this.gVars.getBasketID();
    this.order.TotalCost = this.totalPrice;
    this.order.OrderDate = new Date().toISOString().slice(0,10);
    if(this.gVars.getClientTypeID()==1)
    {
      this.order.OrderType = "Private";
    }else{
      this.order.OrderType = "Retail";
    }
    console.log(this.order);
    this.apiService.createOrder(this.order).subscribe( p => {
      console.log("success");
    });
    //this.router.navigate(['/account']);
  }


}
