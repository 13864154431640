
<!-- banner -->
<div class="banner-w3ls-2">

</div>
<!-- //banner -->
<!-- page details -->
<div class="breadcrumb-agile">
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a href="index.html">Home</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">About Us</li>
    </ol>
</div>
<!-- //page details -->

<!-- about -->
<div class="about py-5" id="about">
    <div class="container py-xl-5 py-lg-3">
        <!-- heading title -->
        <div class="main-title text-center mb-sm-5 mb-4 pb-3">
            <h3 class="tittle text-dark font-weight-bold">About Us</h3>
        </div>
        <!-- //heading title -->
        <div class="row mb-5">
            <div class="col-lg-6 aboutright mt-xl-0 mt-lg-5">
                <img src="assets/images/lap.png" alt="" class="img-fluid">
            </div>
            <div class="col-lg-5 aboutleft ml-xl-4 mt-lg-0 mt-4">
                <h4 class="about-left-agile text-dark text-capitalize mb-2">About
                    <span>Pfusheo</span>
                </h4>
                <p style="text-align: justify;" class="border-left pl-3">Pfusheo is a recently established healthy foods venture that specializes in cooked muroho. We supply Muroho throughtout the whole of South Africa</p>
                <h4 class="about-left-agile text-dark text-capitalize mt-4 mb-2">
                   Meaning of <span>Pfusheo</span> 
                </h4>
                <p style="text-align: justify;" >Pfusheo is a Venda word that means Satisfaction. It is a derivative of “U fusha “ which translates to “to satisfy” followed by “ Pfushi” meaning “Nutrition”. In short we can coil “Pfushi dza miroho ya Pfusheo”.</p>
                <p style="text-align: justify;">Pfusheo nga Pfushi dza Miroho” (i.e. Satisfaction derived from  nutritional Miroho/Traditional Vegetables)</p>
            </div>
        </div>
    </div>
</div>
<!-- //about -->

<!-- 
<div class="serives-agile py-5" id="services">
    <div class="container py-xl-5 py-lg-3">
        <div class="row support-bottom text-center">
            <div class="col-md-4 services-w3ls-grid">
                <i class="fa fa-bullhorn" aria-hidden="true"></i>
                <h4 class="text-white text-uppercase mt-md-5 mt-4 mb-3">Heading Here</h4>
                <p>Ut enim ad minima veniam, quis nostrum ullam corporis suscipit laboriosam.</p>
            </div>
            <div class="col-md-4 services-w3ls-grid my-md-0 my-4">
                <i class="fa fa-cubes" aria-hidden="true"></i>
                <h4 class="text-white text-uppercase mt-md-5 mt-4 mb-3">Heading Here</h4>
                <p>Ut enim ad minima veniam, quis nostrum ullam corporis suscipit laboriosam.</p>
            </div>
            <div class="col-md-4 services-w3ls-grid">
                <i class="fa fa-shopping-basket" aria-hidden="true"></i>
                <h4 class="text-white text-uppercase mt-md-5 mt-4 mb-3">Heading Here</h4>
                <p>Ut enim ad minima veniam, quis nostrum ullam corporis suscipit laboriosam.</p>
            </div>
        </div>
    </div>
</div>



<div class="feature py-5" id="what">
    <div class="container py-xl-5 py-lg-3">
        <div class="row">
            <div class="col-xl-6 col-lg-8 mt-4">
                <h3 class="text-dark font-weight-bold text-mobile-w3">Big Sale up to 30% off on all products</h3>
                <p class="white-forming my-xl-4 my-3 mb-md-0 mb-5">Praesent congue iaculis pulvinar. Praesent sit amet consequat
                    sem, nec scelerisque
                    nisl.</p>
                <div class="agileits-w3layouts-grid pt-md-4">
                    <div class="row wthree_agile_us">
                        <div class="col-lg-3 col-sm-2 col-3 text-center">
                            <div class="icon-effect">
                                <i class="fa fa-apple" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div class="col-lg-9 col-sm-10 col-9 agile-why-text">
                            <h4 class="text-dark mb-3">Fresh Fruits</h4>
                            <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.</p>
                        </div>
                    </div>
                    <div class="row wthree_agile_us my-xl-5 my-lg-4 my-sm-5 my-4">
                        <div class="col-lg-3 col-sm-2 col-3 text-center">
                            <div class="icon-effect grid-w3ls-2">
                                <i class="fa fa-user-secret" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div class="col-lg-9 col-sm-10 col-9 agile-why-text">
                            <h4 class="text-dark mb-3">Support 24/7</h4>
                            <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.</p>
                        </div>
                    </div>
                    <div class="row wthree_agile_us">
                        <div class="col-lg-3 col-sm-2 col-3 text-center">
                            <div class="icon-effect">
                                <i class="fa fa-retweet" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div class="col-lg-9 col-sm-10 col-9 agile-why-text">
                            <h4 class="text-dark mb-3">Free Exchange</h4>
                            <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 order-xl-1 order-2"></div>
            <div class="col-lg-4 order-xl-2 order-1 mt-lg-0 mt-5">
                <div class="phone text-center">
                    <img alt="" src="assets/images/iphone.png" class="img-fluid phone-img-w3ls">
                </div>
            </div>
        </div>
    </div>
</div>



<section class="clients py-5" id="testi">
    <div class="container py-xl-5 py-lg-3">
        <div class="row py-sm-5">
            <div class="col-lg-6 col-md-8">
                <div id="carouselExampleIndicators" class="carousel slide pb-5" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    </ol>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div class="feedback-info">
                                <div class="feedback-top">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sedc dnmo eiusmod. sed do eiusmod tempor
                                        incididunt
                                        ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi</p>
                                    <h4 class="mt-4 text-white font-weight-bold mb-4">Mary Jane</h4>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="feedback-info">
                                <div class="feedback-top">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sedc dnmo eiusmod. sed do eiusmod tempor
                                        incididunt
                                        ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi</p>
                                    <h4 class="mt-4 text-white font-weight-bold mb-4">Steven Wison</h4>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="feedback-info">
                                <div class="feedback-top">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sedc dnmo eiusmod. sed do eiusmod tempor
                                        incididunt
                                        ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi</p>
                                    <h4 class="mt-4 text-white font-weight-bold mb-4">Mary Jane</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-4"></div>
        </div>
    </div>
</section>



<section class="team-sec main-stats-inner py-5" id="stats">
    <div class="container py-xl-5 py-lg-3">
    
        <div class="main-title text-center mb-5 pb-3">
            <h3 class="tittle text-dark font-weight-bold">Title Here</h3>
            <p class="sub-tittle mt-2">Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam,
                quis nostrud exercitation</p>
        </div>
    
        <div class="inner-sec-w3ls-agileits">
            <div class="row stats-con text-center border">
                <div class="col-md-3 col-6 stats_info counter_grid p-0">
                    <i class="fa fa-external-link" aria-hidden="true"></i>
                    <p class="counter font-weight-bold">645</p>
                    <h4>Projects Done</h4>
                </div>
                <div class="col-md-3 col-6 stats_info counter_grid1 p-0">
                    <i class="fa fa-heart-o" aria-hidden="true"></i>
                    <p class="counter font-weight-bold">563</p>
                    <h4>Satisfied Clients</h4>
                </div>
                <div class="col-md-6 main-stats-inner-img">

                </div>
                <div class="col-md-6 main-stats-inner-img two">

                </div>
                <div class="col-md-3 col-6 stats_info counter_grid p-0">
                    <i class="fa fa-magic" aria-hidden="true"></i>
                    <p class="counter font-weight-bold">1145</p>
                    <h4>Awards</h4>
                </div>
                <div class="col-md-3 col-6 stats_info counter_grid2 p-0">
                    <i class="fa fa-smile-o" aria-hidden="true"></i>
                    <p class="counter font-weight-bold">1045</p>
                    <h4>Happy Clients</h4>
                </div>
            </div>
        </div>
    </div>
</section>
 -->
