import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { exist } from 'src/classes/exist';
import { Router, RouterModule, Routes } from '@angular/router';
import { Client } from '../../classes/client';
import { User } from '../../classes/user';
import { ClientAddress } from '../../classes/client-address';
import { GlobalvariablesService } from '../globalvariables.service';


@Component({
  selector: 'app-editaccountdetails',
  templateUrl: './editaccountdetails.component.html',
  styleUrls: ['./editaccountdetails.component.css']
})
export class EditaccountdetailsComponent implements OnInit {

  constructor(private apiService: ApiService,private router:Router,private gVars:GlobalvariablesService) { }

  public fNameVar = this.gVars.getClientName();
  public lNameVar = this.gVars.getClientSurname();
  public eMailVar = this.gVars.getClientEmail();
  public pNumberVar = this.gVars.getClientCell();
  public sNameVar = this.gVars.getStreetName();
  public sNumberVar = this.gVars.getStreetNumber();
  public pCodeVar = this.gVars.getPostalCode();
  public provinceVar = this.gVars.getProvince();



  ngOnInit() {
  }

  //Used to send JSON object to api service with new client information
  clientObj = new Client;
  clientAddressObj = new ClientAddress;
  isValidFN:boolean = true;
  isValidLN:boolean = true;
  isValidEM:boolean = true;
  isValidPN:boolean = true;

  isValidSN:boolean = true;
  isValidSNo:boolean = true;
  isValidPCo:boolean = true;
  showAlert:boolean = false;
  updateSuccess:boolean = false;
  notAllFields=false;

  splChars = "*|,\":<>[]{}`\';.()@&$#%0123456789";
  splChars2 = "*|,\":<>[]{}`\';.()@&$#%abcdefghijklmnopqrstuvwxyz";
  uNamChars = "*|,\":<>[]{}`\';.()@&$#%";
  emChars = "*|,\":<>[]{}`\';()&$#%";

  invFN = false;
  invLN = false;
  invUN = false;
  invEM = false;
  invP = false;
  invSN = false;
  invSNo = false;
  invCellN = false;
  invPC = false;

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  valInput(x : string){
    switch(x) {
      case x = 'firstName' : {
        this.isValidFN=true;
        for(var y:number =0 ; y < this.fNameVar.length ; y++ )
        {
          if(this.splChars.indexOf(this.fNameVar[y])!=-1)
          {
            this.isValidFN = false;
          }
        }        
        if(this.isValidFN)
        {
          document.getElementById(x).classList.add('is-valid');
          document.getElementById(x).classList.remove('is-invalid');
          this.invFN = false;
          break;
        }
        document.getElementById(x).classList.remove('is-valid');
        document.getElementById(x).classList.add('is-invalid');
        this.invFN = true;
        break;
      }
      case x = 'lastName' : {
        this.isValidLN=true;
        for(var y:number =0 ; y < this.lNameVar.length ; y++ )
        {
          if(this.splChars.indexOf(this.lNameVar[y])!=-1)
          {
            this.isValidLN = false;
          }
        }        
        if(this.isValidLN)
        {
          document.getElementById(x).classList.add('is-valid');
          document.getElementById(x).classList.remove('is-invalid');
          this.invLN = false;
          break;
        }
        document.getElementById(x).classList.remove('is-valid');
        document.getElementById(x).classList.add('is-invalid');
        this.invLN = true;
        break;
      }
      case x = 'email' : {
        this.isValidEM=true;
        for(var y:number =0 ; y < this.eMailVar.length ; y++ )
        {
          if(this.emChars.indexOf(this.eMailVar[y])!=-1)
          {
            this.isValidEM = false;
          }
        }        
        if(this.isValidEM)
        {
          if(this.eMailVar.indexOf('@')!=-1)
          {
            document.getElementById(x).classList.add('is-valid');
            document.getElementById(x).classList.remove('is-invalid');
            this.invEM = false;
            break;
          }else{
            document.getElementById(x).classList.remove('is-valid');
            document.getElementById(x).classList.add('is-invalid');
            this.invEM = true;
            break;
          }          
        }
        document.getElementById(x).classList.remove('is-valid');
        document.getElementById(x).classList.add('is-invalid');
        break;
      }
      case x = 'streetName' : {
        this.isValidSN=true;
        for(var y:number =0 ; y < this.sNameVar.length ; y++ )
        {
          if(this.splChars.indexOf(this.sNameVar[y])!=-1)
          {
            this.isValidSN = false;
          }
        }        
        if(this.isValidSN)
        {
          document.getElementById(x).classList.add('is-valid');
          document.getElementById(x).classList.remove('is-invalid');
          this.invSN = false;
          break;
        }
        document.getElementById(x).classList.remove('is-valid');
        document.getElementById(x).classList.add('is-invalid');
        this.invSN = true;
        break;
      }
      case x = 'streetNumber' : {
        this.isValidSNo=true;
        for(var y:number =0 ; y < this.sNumberVar.length ; y++ )
        {
          if(this.splChars2.indexOf(this.sNumberVar[y])!=-1)
          {
            this.isValidSNo = false;
          }
        }        
        if(this.isValidSNo)
        {
          document.getElementById(x).classList.add('is-valid');
          document.getElementById(x).classList.remove('is-invalid');
          this.invSNo = false;
          break;
        }
        document.getElementById(x).classList.remove('is-valid');
        document.getElementById(x).classList.add('is-invalid');
        this.invSNo = true;
        break;
      }
      case x = 'phoneNumber' : {
        this.isValidPN=true;
        for(var y:number =0 ; y < this.pNumberVar.length ; y++ )
        {
          if(this.splChars2.indexOf(this.pNumberVar[y])!=-1)
          {
            this.isValidPN = false;
          }
        }
        if(this.pNumberVar.length!=10){
          this.isValidPN = false;
        }       
        if(this.isValidPN)
        {
          document.getElementById(x).classList.add('is-valid');
          document.getElementById(x).classList.remove('is-invalid');
          this.invCellN = false;
          break;
        }
        document.getElementById(x).classList.remove('is-valid');
        document.getElementById(x).classList.add('is-invalid');
        this.invCellN = true;
        break;
      }
      case x = 'postalCode' : {
        this.isValidPCo=true;
        for(var y:number =0 ; y < this.pCodeVar.length ; y++ )
        {
          if(this.splChars2.indexOf(this.pCodeVar[y])!=-1)
          {
            this.isValidPCo = false;
          }
        }     
        if(this.isValidPCo)
        {
          document.getElementById(x).classList.add('is-valid');
          document.getElementById(x).classList.remove('is-invalid');
          this.invPC = false;
          break;
        }
        document.getElementById(x).classList.remove('is-valid');
        document.getElementById(x).classList.add('is-invalid');
        this.invPC = true;
        break;
      }
      default: {
        break;
      }
    }
  }

  //used to send JSON object to api service with all information to register a new client
  async saveDetails(){
  if(this.isValidFN && this.isValidLN &&  this.isValidEM &&  this.isValidSN && this.isValidSNo && this.isValidPN && this.isValidPCo)
  {
    this.notAllFields=false;
    if(this.fNameVar.length==0 || this.lNameVar.length==0 || this.pNumberVar.length==0 || this.eMailVar.length==0 || this.sNameVar.length==0 || this.sNumberVar.length==0 || this.pCodeVar.length==0)
    {
      this.notAllFields=true;
    }else{
      this.clientAddressObj.PostalCode = this.pCodeVar;
      this.clientAddressObj.Province = this.provinceVar;
      this.clientAddressObj.StreetName = this.sNameVar;
      this.clientAddressObj.StreetNumber = this.sNumberVar;
      this.clientAddressObj.ClientAddressID = this.gVars.getClientAddressID();
  
      this.clientObj.ClientCellNumber = this.pNumberVar;
      this.clientObj.ClientEmail = this.eMailVar;
      this.clientObj.ClientName = this.fNameVar;
      this.clientObj.ClientSurname = this.lNameVar;
      this.clientObj.ClientID = this.gVars.getClientID();
      
      console.log(JSON.stringify(this.clientAddressObj));
      console.log(JSON.stringify(this.clientObj));
  
        this.apiService.updateClientAddress(this.gVars.getClientAddressID(),this.clientAddressObj).subscribe(x=> {
          (JSON.stringify(x));
        })
        await this.delay(100);
        this.apiService.updateClient(this.gVars.getClientID(), this.clientObj).subscribe(z=> {
          (JSON.stringify(z));
        })
        this.updateSuccess=true;
        this.showAlert=false;
        this.valInput('firstName');
        this.valInput('lastName');
        this.valInput('email');
        this.valInput('phoneNumber');
        this.valInput('streetName');
        this.valInput('streetNumber');
        this.valInput('postalCode');
  
        this.gVars.setPostalCode(this.clientAddressObj.PostalCode);
        this.gVars.setStreetName(this.clientAddressObj.StreetName);
        this.gVars.setStreetNumber(this.clientAddressObj.StreetNumber);
        this.gVars.setProvince(this.clientAddressObj.Province);
  
        this.gVars.setClientName(this.clientObj.ClientName);
        this.gVars.setClientSurname(this.clientObj.ClientSurname);
        this.gVars.setClientEmail(this.clientObj.ClientEmail);
        this.gVars.setClientCell(this.clientObj.ClientCellNumber);
  
        sessionStorage.setItem('postalCode',this.clientAddressObj.PostalCode);
        sessionStorage.setItem('streetName',this.clientAddressObj.StreetName);
        sessionStorage.setItem('streetNumber',this.clientAddressObj.StreetNumber);
        sessionStorage.setItem('province',this.clientAddressObj.Province);
  
        sessionStorage.setItem('clientName',this.clientObj.ClientName);
        sessionStorage.setItem('clientSurname',this.clientObj.ClientSurname);
        sessionStorage.setItem('clientEmail',this.clientObj.ClientEmail);
        sessionStorage.setItem('clientCell',this.clientObj.ClientCellNumber);
  
        if(this.gVars.getClientTypeID()==1)
        {
          this.gVars.heading="Welcome "+this.clientObj.ClientName+"!";
        }else{
          this.gVars.heading="Welcome "+this.clientObj.ClientName+"! Retail Account";
        }
      }}else{
        //add alert that invalid info
        this.showAlert = true;
      }
    }   
  }

