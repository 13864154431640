

<!-- banner -->
<div class="banner-w3ls-2">

</div>
<!-- //banner -->
<!-- page details -->
<div class="breadcrumb-agile">
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a href="index.html">Home</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Checkout</li>
    </ol>
</div>
<!-- //page details -->




<table  class="table" style="margin-bottom: 70px;">
        <thead class="black white-text">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Quantity</th>
            <th scope="col">Cost</th>
            <th scope="col">Image</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let product of BasketProducts">
            <td style="font-weight: bold;text-align: center; padding:20px;">{{product.ProductName}}</td>
            <td style="font-weight: bold;text-align: center; padding:20px;">x{{product.Quantity}}</td>
            <td style="font-weight: bold;text-align: center; padding:20px;">R {{product.Cost}}.00</td>
            <td><img id="image"  class="img-fluid zoom-img"  width="125" [src]="product.ProductImage"/></td>
          </tr>
          <tr>
              <td></td>
              <td style="font-weight: bold">Quantity of Items: {{totalQuantity}}</td>
              <td></td>
              <td style="font-weight: bold">Total Price R {{totalPrice}}.00</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td><button type="submit" class="btn aqua-gradient" routerLink="/basket">Cart</button></td>
            <td><!--<button type="button"  (click)="createOrder()" class="btn aqua-gradient" data-toggle="modal" data-target="#basicExampleModal">
                Pay Now with PayFast!
              </button>-->
              <form #form action="https://sandbox.payfast.co.za/eng/process" method="POST">
                <input type="hidden" name="merchant_id" value="10000100">
                <input type="hidden" name="merchant_key" value="46f0cd694581a">
                <input type="hidden" name="return_url" value="http://frontend.lammed.co.za/#/paysuccess">
                <input type="hidden" name="cancel_url" value="http://frontend.lammed.co.za/#/paycancel">
                <input type="hidden" name="amount" value="{{this.totalPrice}}">
                <input type="hidden" name="item_name" value="PfusheoOrder">
                <input type="hidden" name="signature" value="{{MD5string}}">
                <button *ngIf="payable" type="submit" class="btn aqua-gradient" (click)="form.submit()">Pay</button>
              </form> 
            </td>
          </tr>      
          
        </tbody>
      </table>